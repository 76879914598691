import React, {useState} from 'react';
import { TextField, Button, Container, Stack } from '@mui/material';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../assets/css/Content.css";
import Box from '@mui/material/Box';
import { Divider } from 'primereact/divider';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { useNavigate } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ApplicationView = () => {

   const location = useLocation();
   const applicationData = location.state;
   const navigate=useNavigate();
   const [applicationName, setApplicationName] = useState(applicationData.applicationName);
   const [description, setDescription] = useState(applicationData.applicationDescription);
   const [approvalPeriod, setApprovalPeriod] = useState(applicationData.accountApprovalPeriod);
   const [gracePeriod, setGracePeriod] = useState(applicationData.accountGracePeriod);
   const [tabValue, setTabValue] = React.useState(2);
   const formTab={textTransform: 'none',fontSize: '14px',fontFamily: "Arial",'align-items': 'initial',minWidth: 90};
   const applicationNameStyle={textTransform: 'none',fontSize: '14.5px',fontFamily: "Arial",'align-items': 'initial',color: 'red',minWidth: 90};
   const appName = sessionStorage.getItem('ApplicationName');
   const typoStyle={fontSize: '14px', width: '100%' ,height: '30px' ,alignContents:'center'}

   const handleTabChange = (event, tabValue) => {
        setTabValue(tabValue);
   };

   const handleApplicationApi = () => {

             navigate("/applications");

            };

   const handleAuditLog = () => {

             navigate("/auditLog");
             };

   var data =applicationData.constraints;
   const renderedPermission = data.map((item, index) => (
    <>
      {item.name!='Environment' && item.name!='Env Type' && (
       <>

          <TextField
                                                                       type="text"
                                                                       variant='outlined'
                                                                       multiline
                                                                       rows={5}
                                                                       color='secondary'
                                                                       label={item.name}
                                                                       value={item.values.join("\n")} id={item.name} />


          <>
            {index==1 && (
              <br/>
            )}
          </>
       </>
       )}
    </>


    ));
   var auditData =applicationData.auditFieldLabels;
   var auditFieldLabels="Custom field";
   var auditFieldId="Custom_field";
   const renderedAuditFields = auditData.map((item, index) => (
      <dev>
          <TextField type="text"  style={{width: '70%'}} variant='outlined' color='secondary'
              id={auditFieldId+(index+1)}   label={auditFieldLabels +' ' +(index+1)} value={item}  />

          <>
            {index==2 && (
              <br/>
            )}
          </>
      </dev>

   ));

   var userPropertiesData =applicationData.userProperties;
   var accountId="accountId";
   const renderedUserProperties = userPropertiesData.map((item, index) => (
     <dev>
        <label id={accountId+(index+1)} className="app-view-allow-domain">{item} </label>
     </dev>
   ));
   var allowedDomainsData =applicationData.allowedDomains;
   var allowedDomainId="domain";
   const renderedAllowedDomains = allowedDomainsData.map((item, index) => (
     <dev>
       <label id={allowedDomainId+(index+1)} className="app-view-allow-domain">{item}</label>
     </dev>
   ));

    return (
      <div>
      <Paper square>
                                                    <Tabs
                                                      value={tabValue}
                                                      onChange={handleTabChange}
                                                      variant="plain"
                                                      color="neutral">
                                                      <Tab style={applicationNameStyle}  label={appName} />
                                                      <Tab style={formTab} className="navigation-title"  onClick={handleApplicationApi} label="Applications" />
                                                      <Tab style={formTab} className="navigation-title"  label="Details" />
                                                      <Tab style={formTab} className="navigation-title" label="Roles" />
                                                      <Tab style={formTab} className="navigation-title" onClick={handleAuditLog} label="Audit Logs" />
                                                      <Tab style={formTab} className="navigation-title" label="User" />
                                                    </Tabs>
                                                  </Paper>
               <br/>
      <Box className="application-view-form-box">
       <Accordion id="description_accrdn" className="accordion-app-view" defaultExpanded>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2-content"
                                 >
                                  <Typography style={typoStyle}>Description</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Stack id="description_stack" spacing={2} direction="row">
                                                       <TextField
                                                              id="app_name"
                                                              type="text"
                                                              variant='outlined'
                                                              color='secondary'
                                                              label="Application Name"
                                                              value={applicationName} />
                                                       <TextField
                                                          type="text"
                                                          id="app_desc"
                                                          variant='outlined'
                                                          color='secondary'
                                                          label="Description"
                                                          value={description}
                                                          sx={{width:"30%"}}
                                                      />
                                                   </Stack>
                                </AccordionDetails>
             </Accordion>

       <Accordion id="permission_accrdn" className="accordion-app-view" defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content">
                <Typography>Permissions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack id="permission_stack" direction="row" justifyContent="left" alignItems="stretch">
                                  {renderedPermission}
                </Stack>
              </AccordionDetails>
       </Accordion>

      <Accordion id="account_accrn" className="accordion-app-view">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content">
                      <Typography  style={typoStyle}>Account properties</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack id="account_stack" direction="row" justifyContent="left" alignItems="stretch" spacing={2}>
                                         {renderedUserProperties}

                      </Stack>
                    </AccordionDetails>
      </Accordion>

      <Accordion id="auditLog_accrdn" className="accordion-app-view">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content">
                            <Typography>Audit Log</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack id="auditLog_stack" direction="row" justifyContent="left" alignItems="stretch" spacing={2}>
                                               {renderedAuditFields}

                            </Stack>
                          </AccordionDetails>
       </Accordion>

      <Accordion id="allowedDomain_accrdn" className="accordion-app-view">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content">
                            <Typography>Allowed Domains</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                                               {renderedAllowedDomains}

                          </AccordionDetails>
      </Accordion>

       <Accordion id="extrn_accnt_accrdn" className="accordion-app-view">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2-content">
                                  <Typography>External Account Monitoring</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Stack id="extrn_accnt_stack" spacing={2} direction="row" sx={{marginBottom: 4}}>

                                                  <TextField id="approval_period" type="text"  variant='outlined' color='secondary' label="Approval Period(days)"
                                                    value={approvalPeriod} inputProps={{ readOnly: true, }} sx={{mb: 8}}/>

                                                  <TextField id="grace-period" type="text" variant='outlined' color='secondary' label="Grace Period(days)"
                                                   value={gracePeriod} inputProps={{ readOnly: true, }} sx={{mb: 8}}/>
                                                    </Stack>

                                </AccordionDetails>
       </Accordion>

      </Box>
  </div>
    )
}

export default ApplicationView;
