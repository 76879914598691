import * as React from 'react';
import {useState,useEffect} from "react";
import "../assets/css/Content.css";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation } from "react-router-dom";
import {SubmitBtn} from "./FormComponent";
import IconButton from '@mui/material/IconButton';
import { configData } from "../config/config-dev";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import "../assets/css/Content.css";
import {Table,TableBody,TableCell,TableRow,FormControl,InputLabel,Select,MenuItem} from '@mui/material';
import {DataGrid,GridToolbarQuickFilter,GridLogicOperator} from '@mui/x-data-grid';
import AppsIcon from '@mui/icons-material/Apps';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@mui/icons-material/Delete';


const UserSession = () => {

     const formTab={textTransform: 'none',fontSize: '14px',fontFamily: "Arial",'align-items': 'initial',minWidth: 90,'font-weight': 'bold'};
     const navigate=useNavigate();
     const sessionId = sessionStorage.getItem('SessionId');
     const url = configData.gateWayUrl + configData.inValidateUrl;
     const location = useLocation();
     const userSessionList = location.state;
     const sessionsUrl = configData.gateWayUrl + configData.sessions;
     const [sessionData,setSessionData]=useState([]);

     const columns = [
       { field: 'email',headerName: 'Email', flex: 1},
       { field: 'accountName',headerName: 'Account Name', flex: 1},
       { field: 'created',headerName: 'Created', flex: 1},
       { field: 'lastUsed',headerName: 'Last Used', flex: 1},
       { field: 'idleTime',headerName: 'Idle Time', flex: 1},
       { field: 'action',headerName: 'Action',flex: 0.7,sortable: false,filterable: false,
       renderCell: (params) => {
          return (
          <Table>

             <TableRow>
                 <TableCell>
                   <Tooltip title="In Validate Session"><IconButton color="success" size="small" color="primary" onClick={(e) => handleInValidateSession(e, params.row)} >
                       <DeleteIcon/>   </IconButton></Tooltip>

      </TableCell>
        </TableRow>
        </Table>
       );} },
        { field: 'sessionId',headerName: 'Session Id'},
     ];

    function QuickSessionSearchToolbar() {
      return (
        <Box className="dataGrid_box" id="application_box" >
          <GridToolbarQuickFilter
                 quickFilterParser={(searchInput) =>
                   searchInput
                     .split(',')
                     .map((value) => value.trim())
                     .filter((value) => value !== '')
                 }
               />
        </Box>
      );
    }
    useEffect(()=> {

     	const getUserSessionData=async ()=> {
     		await axios.post(sessionsUrl,{
     			  sessionId: sessionId
     		}).then(response=>{
     		if(response.data) {
     		    setSessionData(response.data);
     		}
     		})
     		.catch(error=>{
     		console.error('Error while fetching User Session Data',error);
     	  });
     	  };
         getUserSessionData();
        },[]);

    const handleInValidateSession = (e, row) => {

      if(sessionData.sessionId!=sessionId){
            axios.get(url, {
                     params: {
                        userSessionId: row.sessionId,
                        adminSessionId: sessionId,
                      }
                     })
                   .then(function (response) {
                      getUserSession();
                   })
                   .catch(function (error) {
                     console.log(error);
                     getUserSession();
                   });
                   }
        };


         const getUserSession = () => {

                    axios.get(sessionsUrl, {
                             params: {
                                sessionId: sessionId
                              }
                             })
                           .then(function (response) {
                                     if(response.data) {
                                		    setSessionData(response.data);
                                     }
                           })
                           .catch(function (error) {
                             console.log(error);
                           });
                };


    return (
      <div>
           <Paper square>
                  <Tabs
                               variant="plain"
                               color="neutral">
                               <Tab style={formTab} label="Sessions" />
                 </Tabs>
           </Paper>
                <br/>
           <Box id="form-box" className="user-session-form-box">
           <DataGrid id="application_dataGrid" sx={{boxShadow: 2,border: 2,borderColor: 'lightblack',
                                                                                                      '& .MuiDataGrid-cell:hover': {
                                                                                                        color: 'primary.main',
                                                                                                      },
                                                                                                      '& .MuiDataGrid-cell': {
                                                                                                        fontSize: '12px',
                                                                                                      },
                                                                                                      '& .MuiDataGrid-columnHeader': {
                                                                                                         color: 'primary.main',
                                                                                                         fontSize: '12px',
                                                                                                       },
                                                                                                }}

                               columns={columns}
                               getRowId={(sessionData) => sessionData.idleTime}
                               rows={sessionData}
                                       slots={{ toolbar: QuickSessionSearchToolbar }}
                                          disableColumnSelector
                                          disableDensitySelector
                                initialState={{
                                   columns: {
                                     columnVisibilityModel: {
                                       sessionId: false,
                                     },
                                   },
                                 }}
           />
           </Box>
    </div>
    );
};

export default UserSession;