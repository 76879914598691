// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    height: 100vh;
    padding-left: 5%;
    padding-top: 6%;

}
.button-group{
display: flex;
gap: 20%;
}
.button{
padding: 40% 40%;

font-size: 30px;

border: 1px;
border-radius: 4px;
cursor: pointer;
height: 100px;
width: 380px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Reporting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,eAAe;;AAEnB;AACA;AACA,aAAa;AACb,QAAQ;AACR;AACA;AACA,gBAAgB;;AAEhB,eAAe;;AAEf,WAAW;AACX,kBAAkB;AAClB,eAAe;AACf,aAAa;AACb,YAAY;AACZ","sourcesContent":[".container{\n    display: flex;\n    justify-content: flex-start;\n    align-items:flex-start;\n    height: 100vh;\n    padding-left: 5%;\n    padding-top: 6%;\n\n}\n.button-group{\ndisplay: flex;\ngap: 20%;\n}\n.button{\npadding: 40% 40%;\n\nfont-size: 30px;\n\nborder: 1px;\nborder-radius: 4px;\ncursor: pointer;\nheight: 100px;\nwidth: 380px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
