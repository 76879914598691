import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { configData } from "../config/config-dev";
import { config } from "../config/config-local";
import "../assets/css/Navigation.css";
import Tooltip from '@mui/material/Tooltip';
import { Sidebar, Menu, MenuItem} from "react-pro-sidebar";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import ApplicationIcon from '@mui/icons-material/Apps';
import UsersIcon from "@mui/icons-material/PeopleOutlined";
import SessionsIcon from '@mui/icons-material/GridView';
import ReportingIcon from "@mui/icons-material/ReceiptOutlined";
import BulkOperationIcon from '@mui/icons-material/UploadFile';
import GradingIcon from '@mui/icons-material/Grading';
import LockIcon from '@mui/icons-material/Lock';
import { useLocation } from "react-router-dom";

const sideBarNavigation = () => {


    const navigate=useNavigate();
    const location = useLocation();
    const userRole=sessionStorage.getItem('userRole');
    const getUserUrl = configData.gateWayUrl + configData.getUser;
    const globalAdmin = configData.globalAdmin;
    const appAdmin = configData.appAdmin;
    const navBarColor = configData.navBarColor;
    const sessionId = sessionStorage.getItem('SessionId');
    const active = {backgroundColor: 'red',color: 'white'}
    const inactive = {}
    const [selected, setSelected] = React.useState(0);

    const iconList = {
         'HomeIcon': HomeIcon,
         'ApplicationIcon': ApplicationIcon,
         'UsersIcon': UsersIcon,
         'SessionsIcon' : SessionsIcon,
         'ReportingIcon': ReportingIcon,
         'BulkOperationIcon': BulkOperationIcon

     }
     const menuList= [];

   if(userRole==globalAdmin){
       {JSON.parse(configData.globalAdminMenuList).map((data, index) => (
            menuList.push({name: data.name, path: data.path, icon: iconList[data.icon] })
        ))}
    }else if(userRole==appAdmin){
        {JSON.parse(configData.appAdminMenuList).map((data, index) => (
             menuList.push({name: data.name, path: data.path, icon: iconList[data.icon] })

         ))}
    }else {
         {JSON.parse(configData.userMenuList).map((data, index) => (
              menuList.push({name: data.name, path: data.path, icon: iconList[data.icon] })

          ))}
    }
    const handleMenuItemClick = (name,path,index) => {
      setSelected(index);
      if(name=="Users"){
      handleUsersApi(path);
      }else {
       navigate(path);
       }
     };

const handleUsersApi = (path) => {

   axios.post(getUserUrl, {
           sessionId: sessionId,
           })
         .then(function (response) {
           if (response.data) {
           navigate(path, {state: response.data});
           }})
         .catch(function (error) {
           console.log(error);
           navigate(path);
         });
     };


return (
     <div>
     <Sidebar
        backgroundColor={navBarColor}
        height="100%"
        width="70px"
        collapsedWidth="4.5rem"
        breakPoint='md'
        style={{
         height: "80vh",
         top: 'auto',
         position: 'sticky',
         padding: '0rem',
         margin: '0rem',
        }}>
           <Menu  menuItemStyles={{
                                               button: ({ level, active }) => {
                                                 if (level === 0 || level === 1 ) {
                                                   return {
                                                     "&:hover" : {
                                                       backgroundColor: 'white',
                                                       color:  'red',
                                                     }
                                                   }
                                                 }
                                               },
                                             }}>
             <div>
              {menuList.map((data, index) => (
                            <Tooltip title={data.name} placement="right">
                                <MenuItem style={selected == (index+1) ? active : inactive}
                                           onClick={() => handleMenuItemClick(data.name,data.path,(index+1))}
                                           icon={<data.icon />}
                                           />
                            </Tooltip>
                   ))}
             </div>
           </Menu>
           </Sidebar>
    </div>
  );
};

export default sideBarNavigation;