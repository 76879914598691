import React from "react";
import Contents from "../components/EditUserDetails";
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import CdbSideBar from "../components/CdbSideBar";
import {Table,TableBody,TableCell,TableRow} from '@mui/material';

const EditUserDetails = () => {
   return (
   <div style={{background: '#d3d3d324'}}>
   <Header/>
   <div>

      <Table>

      <TableRow>
          <TableCell>
   <CdbSideBar/>
   </TableCell>
   <TableCell>
   <Contents/>
   </TableCell>
   </TableRow>
   </Table>
   </div>
   <Footer/>
   </div>
   );
};
export default EditUserDetails;

