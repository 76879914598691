import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {  CardActionArea, CardActions ,Typography,IconButton} from '@mui/material';
import Icon from '@mui/material/Icon'
import { useNavigate } from 'react-router-dom';

const Cards=({title,icon,cardColor,iconColor,path})=>{
    const navigate=useNavigate();
        const handleApi =()=>{
                 navigate(path);
                 };

    return(
        <Card  onClick= {handleApi}
            sx={{
            maxWidth: 300,
            margin: '10px',
            minWidth: 300,
            minHeight: 200,
            maxHeight: 200,
            padding: '16px',
            textAlign: 'center',
            backgroundColor: cardColor,
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            }}>
        <CardActionArea>
        <CardContent sx={{display: 'flex',  justifyContent: 'center', flexDirection: 'column'}}>
           <IconButton sx={{marginBottom: 6,marginTop: 5,height: 7 }} color={iconColor}>
                <Icon sx={{color: "white"}}>{icon}</Icon>
                </IconButton>
                <Typography sx={{color: "white" }} gutterBottom variant="h6" component="div">
                    {title}
                    </Typography>
            </CardContent>
        </CardActionArea>
     </Card>
        );
    }
export default Cards;