import React, { useState, useEffect } from 'react';
import "../assets/css/Common.css";

import "../assets/css/Content.css";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import { configData } from "../config/config-dev";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { InputText } from 'primereact/inputtext';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip
} from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
export default function UserDataTableApplication() {
const location = useLocation();
const navigate=useNavigate();
const urlglobla =   configData.gateWayUrl + configData.globalEditUserParams;
const columns = [
  { field: 'userId',headerName: 'User Id', flex: 1},
  { field: 'email',headerName: 'Email', flex: 1},
  { field: 'firstname',headerName: 'First name', flex: 1},
  { field: 'surname',headerName: 'Surname', flex: 1},
  { field: 'company',headerName: 'Company', flex: 1},
  { field: 'department',headerName: 'Department', flex: 1},
  { field: 'internal',headerName: 'Internal', flex: 1},
  { field: 'authenticator',headerName: 'Authenticator', flex: 1},
  { field: 'action',headerName: 'Action', headerAlign: 'center',flex: 2,sortable: false,filterable: false,
   description : 'Some fields are only available for local users (NonVodafone)',
  renderCell: (params) => {
     return (
     <Table>

        <TableRow>
            <TableCell>
              <Tooltip title="Edit"><IconButton color="success" size="large" color="primary" onClick={(e) => handleEdit(e, params.row)} >
                     <EditIcon /></IconButton></Tooltip>
              <Tooltip title="Permission"><IconButton color="success" size="large" color="primary">
                      <AccountTreeIcon/></IconButton></Tooltip>
              <Tooltip title="Delete"><IconButton color="success" size="large" color="primary">
                     <DeleteIcon /></IconButton></Tooltip>
             <Tooltip title="Change Password"><IconButton color="success" size="large"color="primary">
                     <PasswordIcon /></IconButton></Tooltip>
 </TableCell>
   </TableRow>
   </Table>


                         );} },
];
const [rowss, setrowss] = useState(location.state);
const getUserurl = configData.gateWayUrl + configData.GetUser;
const sessionId = sessionStorage.getItem('SessionId');
const searchurl = configData.gateWayUrl + configData.accountListUserDetails;
var usersList = location.state;
const [username, setUsername] = useState(null);
const [email, setEmail] = useState(null);
const [firstname, setFirstname] = useState(null);
const [surname, setSurname] = useState(null);
const [company, setCompany] = useState(null);
const [department, setDepartment] = useState(null);
const appName = sessionStorage.getItem('ApplicationName');
 const handleUserName = (e) => {
  e.preventDefault();
       setUsername(e.target.value);
  };
 const handleEmail = (e) => {
 e.preventDefault();
       setEmail(e.target.value);
  };
  const handleFirstname = (e) => {
  e.preventDefault();
    setFirstname(e.target.value);
  };
 const handleSurname = (e) => {
 e.preventDefault();
       setSurname(e.target.value);
  };
  const handleCompany = (e) => {
  e.preventDefault();
    setCompany(e.target.value);
  };
 const handleDepartment = (e) => {
 e.preventDefault();
       setDepartment(e.target.value);
  };

const handleSubmit = async() => {
   axios.post(searchurl, {
              sessionId : sessionId,
              user_username : username,
              user_email:email,
              user_firstname:firstname,
              user_surname:surname,
              user_company:company,
              user_department:department,
           })
         .then(function (response) {
           if (response.data) {
           usersList= response.data;
           setrowss(response.data)
           }
         })
         .catch(function (error) {
           console.log(error);
           alert(error);
            navigate("/applications");
         });
};
const handleEdit =  (e, row) => {
                       axios.post(urlglobla, {
                                     account : row.account,
                                     sessionId : sessionId,

                                  })
                                .then(function (response) {
               navigate("/editUserDetails", {state: response.data});
                                })
                                .catch(function (error) {
                                  console.log(error);
                                  alert(error);
                                   navigate("/applications");
                                });
                   };
    return (
   <div>
           <Box
                   sx={{
                     display: 'flex',
                     flexWrap: 'wrap-reverse',
                     p: 1,
                     m: 1,
                     bgcolor: 'background.paper',
                     borderRadius: 1,
                     border: '1px solid',
                   }}
                 >

        <div class="scroll">
 <DataGrid  sx={{
               '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold !important',
                  overflow: 'visible !important',
               },
               '.MuiDataGrid-rowHeaderTitle': {
                                 backgroundColor: "#99D9EA"
                              },
               boxShadow: 2,
                   border: 2,
                   borderColor: 'primary.light',
                   '& .MuiDataGrid-cell:hover': {
                     color: 'primary.main',
                   },
             }}
        getRowId={(usersList) => usersList.id}
        rows={rowss}
        columns={columns}
        rowsPerPageOptions={[]}
        hideFooterPagination
        showColumnVerticalBorder
        initialState={{

                }}
        />
      </div>

   <Table>
   <TableRow>
          <TableCell colSpan={6}>
           <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
 <h4 className="m-0">Users of {appName}</h4>
 </div>
 </TableCell>
</TableRow>

   <TableRow>
       <TableCell> <TextField
            id="user_username"
            label="Search by User Name"
            margin = "dense"
            variant = "outlined"
            onChange={handleUserName}
        /></TableCell>
        <TableCell><TextField
                            id="user_email"
                            label="Search by Email"
                            margin = "dense"
                            variant = "outlined"
                            onChange={handleEmail}
                        /></TableCell>
        <TableCell><TextField
            id="user_firstname"
            label="Search by First Name"
            margin="normal"
            margin = "dense"
            variant = "outlined"
            onChange={handleFirstname}
        /></TableCell>
        <TableCell><TextField
            id="user_surname"
            label="Search by Surname"
            margin = "dense"
            variant = "outlined"
            onChange={handleSurname}
        /></TableCell>
        <TableCell><TextField
                    id="user_company"
                    label="Search by Company"
                    margin = "dense"
                    variant = "outlined"
                    onChange={handleCompany}
                /></TableCell>
                <TableCell><TextField
                            id="user_department"
                            label="Search by Department"
                            margin = "dense"
                            variant = "outlined"
                            onChange={handleDepartment}
                        /></TableCell>
                        </TableRow>
         <TableRow>
        <TableCell sx={{ borderBottom: "none" }}><Button variant="contained" onClick={handleSubmit}>Search</Button></TableCell>
        <TableCell sx={{ borderBottom: "none" }}><Button variant="contained" type="submit">Reset</Button></TableCell>
                       </TableRow>
        </Table>
        </Box>
         <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
<TableCell sx={{ borderBottom: "none" }}><Button variant="contained" onClick={handleSubmit}>Add User</Button></TableCell>
</div>
        </div>
    );
}
