import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {  CardActionArea, CardActions ,Typography,IconButton} from '@mui/material';
import Icon from '@mui/material/Icon'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { configData } from "../config/config-dev";

const MyCard=({title,icon,cardColor,iconColor,path})=>{
    const appName = sessionStorage.getItem('ApplicationName');
        const sessionId = sessionStorage.getItem('SessionId');
    const viewAppUrl = configData.gateWayUrl + configData.application;
    const getUserUrl = configData.gateWayUrl + configData.getUserApplication;


        const viewApplication = (event, appId) => {

                                         axios.post(viewAppUrl, {
                                                  sessionId: sessionId,
                                                  applicationName: appName
                                                  })
                                                .then(function (response) {
                                                  if (response.data) {
                                                   sessionStorage.setItem('ApplicationName',response.data.applicationName);
                                                   navigate("/viewApplication", {state: response.data});
                                                   } else {
                                                   navigate("/dashboard");
                                                  }
                                                })
                                                .catch(function (error) {
                                                  navigate("/dashboard");
                                         });
            };
        const handleUserApi = () => {


           axios.post(getUserUrl, {
                   sessionId: sessionId,
                   appId: appName,
                   filters: {

                                       },
                   })
                 .then(function (response) {
                   if (response.data) {
                    navigate("/getUserApplication", {state: response.data});
                   } else {
                   }
                 })
                 .catch(function (error) {
                   console.log(error);
                   navigate("/getUserApplication");
                 });
             };
    const navigate=useNavigate();
        const handleApi =()=>{
            if(path=='/viewApplication')
            {
                viewApplication();
                }
            else if(path=='/getUserApplication'){
                handleUserApi();
                }
            {
                 navigate(path);
                 }
                 };


    return(
        <Card onClick= {handleApi}
            sx={{
            maxWidth: 150,
            margin: '10px',
            minWidth: 180,
            minHeight: 115,
            maxHeight: 135,
            padding: '16px',
            textAlign: 'center',
            backgroundColor: cardColor,
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            }}>
        <CardActionArea>
        <CardContent sx={{display: 'flex',  justifyContent: 'center', flexDirection: 'column'}}>
           <IconButton sx={{marginBottom: 0.5 }} color={iconColor}>
                <Icon sx={{color: "white",}}>{icon}</Icon>
                </IconButton>
                <Typography  sx={{color: "white"}} gutterBottom variant="h6" component="div">
                    {title}
                    </Typography>
            </CardContent>
        </CardActionArea>
     </Card>
        );
    }
export default MyCard;