import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {  CardActionArea, CardActions } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import auditLogImage from "../assets/images/vod_bac1.png";
import SessionImage from "../assets/images/img_9.png";
import ReportingImage from "../assets/images/img_6.png";
import ApplicationImage from "../assets/images/img_8.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../assets/css/Content.css";



const slides = [
  {
    label: 'Applications',
    imgPath:
      ApplicationImage,
    path: '/applications',
  },
  {
    label: 'Reporting',
    imgPath:
      ReportingImage,
    path: '/reporting',
  },
  {
    label: 'Audit logs',
    imgPath: auditLogImage,
    path: '/applications',
  },
  {
    label: 'Sessions',
    imgPath:
      SessionImage,
    path: '/sessions',
  },
];

function MySlider() {
    const settings={
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
        autoPlaySpeed: 5000,
        fade: true,
        waitForAnimate: false,


        };
    const navigate=useNavigate();

  const handleApi =(path)=>{
         navigate(path);
         };
  return (
      <div id="slick-slider">
    <Slider  {...settings} >
        {slides.map((slide,index)=>(
            <div key={index}>
                <img src={slide.imgPath} alt={slide.label} width="100%" height="50%"/>
                <h2>{slide.label}</h2>
                <a href={slide.path}></a>
            </div>
            ))}

    </Slider>
    </div>

  );
}

export default MySlider;