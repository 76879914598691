import * as React from 'react';
import {Container,Grid} from '@mui/material';
import Cards from '../components/Cards'
import Slider from '../components/Slider'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AppsIcon from '@mui/icons-material/Apps'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { useNavigate } from 'react-router-dom';
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import CdbSideBar from "../components/CdbSideBar";
import "../assets/css/Content.css";
import {Table,TableBody,TableCell,TableRow} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import {  CardActionArea, CardActions ,Typography,IconButton,Button} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { configData } from "../config/config-dev";
import axios from "axios";

const AddUser=()=>{
    const navigate=useNavigate();
    const appName = sessionStorage.getItem('ApplicationName');
    const sessionId = sessionStorage.getItem('SessionId');
    const sidebarTd={ paddingLeft: '0.0%',paddingTop: '0.0%',width: '10%',verticalAlign: 'top', textAlign: 'left'};
     const contentsTd={ paddingLeft: '0.0%',paddingTop: '0.0%',verticalAlign: 'top', textAlign: 'left'};
    const getUserUrl = configData.gateWayUrl + configData.getUserApplication;
    const handleUser = () => {
    navigate("/getUserApplication");
    }
const handleUserApi = () => {


   axios.post(getUserUrl, {
           sessionId: sessionId,
           appId: appName,
           filters: {

                               },
           })
         .then(function (response) {
           if (response.data) {
            navigate("/getUserApplication", {state: response.data});
           } else {
           }
         })
         .catch(function (error) {
           console.log(error);
           navigate("/getUserApplication");
         });
     };

    return(
        <div>
            <Header/>
            <div>
                   <Table>
                   <TableRow>
                       <TableCell style={sidebarTd}>
                <CdbSideBar/>
                </TableCell>

                <TableCell style={contentsTd}>
                <div sx={{marginTop: "3%", marginLeft: "-3%"}}>
                    <Typography sx={{color: "Black",paddingTop: "3%",paddingBottom: "3%"}} gutterBottom variant="h6" component="div">
                         <AdminPanelSettingsIcon/> Administrator for {appName} | <PersonAddAlt1Icon/> Add Users
                        </Typography>

                                      <div className="button-group">

                                        <Tooltip title="Assign users ">
                                         <Card sx={{ maxWidth: 445 }}>
                                              <CardActionArea>
                                                <CardMedia
                                                  component="img"
                                                  height="260"
                                                  src={require('./../assets/images/img_11.png')}
                                                  alt="Assign Users"
                                                  onClick={handleUserApi}
                                                />
                                                <CardContent>
                                                  <Typography gutterBottom variant="h5" component="div">
                                                    Assign Users
                                                  </Typography>
                                                  <Typography variant="body1" color="text.secondary">
                                                    Assign existing vodafone or non-vodafone users to the Application: {appName}.
                                                  </Typography>
                                                </CardContent>
                                              </CardActionArea>
                                            </Card>
                                        </Tooltip>
                                        <Tooltip title="Create user and assign to the application">
                                        <Card sx={{ maxWidth: 445 }}>
                                                                      <CardActionArea>
                                                                        <CardMedia
                                                                          component="img"
                                                                          height="260"
                                                                          src={require('./../assets/images/img_10.png')}
                                                                          alt="Create New User"
                                                                          onClick={handleUser}
                                                                        />
                                                                        <CardContent>
                                                                          <Typography gutterBottom variant="h5" component="div">
                                                                            Create New User
                                                                          </Typography>
                                                                          <Typography variant="body1" color="text.secondary">
                                                                            Create a new user and assign to your application
                                                                         </Typography>
                                                                        </CardContent>
                                                                      </CardActionArea>
                                                                    </Card>
                                        </Tooltip>
                                        </div>
                                        </div>
                </TableCell>

                </TableRow>
                </Table>
                </div>
            <Footer/>
            </div>

        );
    }
export default AddUser;