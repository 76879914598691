// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 236px;
  background-color: #ffe4e1;
  height: 900px;
}

.applicationIcon {

    margin-top: 44px;
    margin-left: 44px;
    width: 81px;
    height: 47px;
}
.active {
  background-color: #a316c7;
  color: white;
}

.menuItem:hover:not(.active) {
  /*background-color: #a316c7;*/
  background-color: #ffffff;
  color: black;
}

#contentdiv {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

.divHeader
{
margin-top: -1px
}

.css-1r9jet7{
background: lightcoral;
height: 70px;
}
.css-1191obr-MuiPaper-root-MuiAppBar-root {
margin-top: 80px
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
margin-top: 80px;
}
.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
margin-top: 80px
}
.css-hyum1k-MuiToolbar-root{
background: lightcoral;
height: 70px;
}

.sc-hknOHE {
height:50px;
}
.eETGfq {

    background: #f4f4f4;
    width: 30%;
    margin-left:-13%;
    margin-top: -18%;
}
.cEoELS {
     background: #f4f4f4;
}
.css-1wvake5{
width: 78px;
min-width: 0;
}

.menu-item{
   background: #FFFFFF !important;

}
.menu-item:hover{
   background: #FFFFFF  !important;
   color: #FF0000;
}


`, "",{"version":3,"sources":["webpack://./src/assets/css/Navigation.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;AACf;;AAEA;;IAEI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;;AAEA;AACA;;AAEA;AACA,sBAAsB;AACtB,YAAY;AACZ;AACA;AACA;AACA;AACA;AACA,gBAAgB;AAChB;AACA;AACA;AACA;AACA;AACA,sBAAsB;AACtB,YAAY;AACZ;;AAEA;AACA,WAAW;AACX;AACA;;IAEI,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,gBAAgB;AACpB;AACA;KACK,mBAAmB;AACxB;AACA;AACA,WAAW;AACX,YAAY;AACZ;;AAEA;GACG,8BAA8B;;AAEjC;AACA;GACG,+BAA+B;GAC/B,cAAc;AACjB","sourcesContent":[".sidebar {\n  width: 236px;\n  background-color: #ffe4e1;\n  height: 900px;\n}\n\n.applicationIcon {\n\n    margin-top: 44px;\n    margin-left: 44px;\n    width: 81px;\n    height: 47px;\n}\n.active {\n  background-color: #a316c7;\n  color: white;\n}\n\n.menuItem:hover:not(.active) {\n  /*background-color: #a316c7;*/\n  background-color: #ffffff;\n  color: black;\n}\n\n#contentdiv {\n  margin-left: 200px;\n  padding: 1px 16px;\n  height: 1000px;\n}\n\n.divHeader\n{\nmargin-top: -1px\n}\n\n.css-1r9jet7{\nbackground: lightcoral;\nheight: 70px;\n}\n.css-1191obr-MuiPaper-root-MuiAppBar-root {\nmargin-top: 80px\n}\n.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {\nmargin-top: 80px;\n}\n.css-19z1ozs-MuiPaper-root-MuiAppBar-root {\nmargin-top: 80px\n}\n.css-hyum1k-MuiToolbar-root{\nbackground: lightcoral;\nheight: 70px;\n}\n\n.sc-hknOHE {\nheight:50px;\n}\n.eETGfq {\n\n    background: #f4f4f4;\n    width: 30%;\n    margin-left:-13%;\n    margin-top: -18%;\n}\n.cEoELS {\n     background: #f4f4f4;\n}\n.css-1wvake5{\nwidth: 78px;\nmin-width: 0;\n}\n\n.menu-item{\n   background: #FFFFFF !important;\n\n}\n.menu-item:hover{\n   background: #FFFFFF  !important;\n   color: #FF0000;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
