import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import axios from "axios";
import { configData } from "../config/config-dev";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

const EditDetails = (props) => {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const isVodafoneUser = false;
  const [userDetails, setUserDetails] = React.useState("");
  const url = configData.gateWayUrl + configData.getUserDetails;
  const updateUserDetails =
    configData.gateWayUrl + configData.UpdateUserDetails;
  const sessionIds = sessionStorage.getItem("SessionId");
  const getUserDetails = () => {
    axios
      .post(url, {
        sessionId: sessionIds,
      })
      .then(function (response) {
        setUserDetails(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  window.addEventListener("beforeunload", (event) => {
    getUserDetails();
  });
  const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick")
          return;
      props.close();
  }
  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const editFirstName = formJson.editFirstName;
            const editSurName = formJson.editSurName;
            const editPhone = formJson.editPhone;
            const editCompany = formJson.editCompany;
            const editDepartment = formJson.editDepartment;
            axios
              .post(updateUserDetails, {
                name: editFirstName,
                surName: editSurName,
                phoneNumber: editPhone,
                company: editCompany,
                department: editDepartment,
                sessionId: sessionIds,
              })
              .then(function (response) {
                if (response.data.isSuccess) {
                  props.close;
                } else if (
                  response.data.errorDetails !== null &&
                  response.data.errorDetails !== ""
                ) {
                  setErrorMessage(response.data.errorDetails);
                  setOpen(true);
                }
              });
            props.close;
          },
        }}
      >
        {!isVodafoneUser && <DialogTitle>Edit Details </DialogTitle>}
        {isVodafoneUser && <DialogTitle>View Details</DialogTitle>}
        <DialogContent>
          <TextField
            autoFocus
            required
            disabled
            margin="dense"
            id="userId"
            name="userId"
            label="User Id"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.userId}
          />
          <TextField
            autoFocus
            required
            disabled
            margin="dense"
            id="vfuid"
            name="vfuid"
            label="Vfuid"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.vfuid}
          />
          <TextField
            autoFocus
            required
            disabled
            margin="dense"
            id="editemail"
            name="editemail"
            label="Email"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.email}
          />
          <TextField
            autoFocus
            required
            disabled={isVodafoneUser}
            margin="dense"
            id="editFirstName"
            name="editFirstName"
            label="Name"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.name}
          />
          <TextField
            autoFocus
            disabled={isVodafoneUser}
            margin="dense"
            id="editSurName"
            name="editSurName"
            label="Surname"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.surName}
          />
          <TextField
            autoFocus
            disabled={isVodafoneUser}
            margin="dense"
            id="editPhone"
            name="editPhone"
            label="Phone"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.phoneNumber}
          />
          <TextField
            autoFocus
            disabled={isVodafoneUser}
            margin="dense"
            id="editCompany"
            name="editCompany"
            label="Company"
            fullWidth
            variant="outlined"
            defaultValue={userDetails.company}
          />
          <TextField
            autoFocus
            disabled={isVodafoneUser}
            margin="dense"
            id="editDepartment"
            name="editDepartment"
            label="Department"
            fullWidth
            variant="outlined"
            sx={{
              input: {
                color: "#12355B",
                background: "#EFF4F9",
              },
            }}
            inputProps={{ style: { fontFamily: "nunito", fontSize: 15 } }}
            defaultValue={userDetails.department}
          />

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 500,
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
              }}
            >
              <ModalClose variant="soft" sx={{ m: 1 }} />
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
                Message
              </Typography>
              <Typography id="modal-desc" textColor="text.tertiary">
                <code>{errorMessage}</code>
              </Typography>
            </Sheet>
          </Modal>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default EditDetails;
