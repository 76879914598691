import React, {useState,useEffect, useRef } from 'react';
import "../assets/css/Content.css";
import Box from '@mui/material/Box';
import {TextField, Button, Stack } from '@mui/material';
import { configData } from "../config/config-dev";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import {Table,TableBody,TableCell,TableRow,FormControl,InputLabel,Select,MenuItem} from '@mui/material';
import {DataGrid,GridToolbarQuickFilter,GridLogicOperator} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from "@mui/material/InputAdornment";
import HistoryIcon from '@material-ui/icons/History';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import {Panel,FlexboxGrid} from "rsuite";


const AuditLog = () => {


const formTab={textTransform: 'none',fontSize: '14px',fontFamily: "Arial",'align-items': 'initial',minWidth: 90};
const appNameStyle={textTransform: 'none',fontSize: '14.5px',fontFamily: "Arial",'align-items': 'initial',color: 'red',minWidth: 90};
const dateTimeStyle={width: '150px'};
const [loading, setLoading] = React.useState(false);
const [success, setSuccess] = React.useState("");

const navigate=useNavigate();

const viewAppUrl = configData.gateWayUrl + configData.application;
const auditLogDetails = configData.gateWayUrl + configData.auditLogDetails;

const appName = sessionStorage.getItem('ApplicationName');
const userSessionId = sessionStorage.getItem('SessionId');

var appFromDate,appToDate,appFromTime,appToTime;
const actionData=JSON.parse(configData.actionType);
const statusData=configData.responseStatus;
const [action, setAction] = React.useState(null);
const [status, setStatus] = React.useState(null);
const emailId= useRef(null);
const sessionId= useRef(null);

const defaultToTime=new Date().toLocaleString("en-US",{timeZone: "GMT"}).toString("MM/dd/yyyy hh:mm:ss aa");
const defaultFromTime= new Date(Date.now() - 1 * (60 * 60 * 1000)).toLocaleString("en-US",{timeZone: "GMT"}).toString("MM/dd/yyyy hh:mm:ss aa");
const defaultDate=new Date().toLocaleDateString("en-US", {timeZone: "GMT"},{ year: 'numeric', day: '2-digit', month: '2-digit' }).toString("dd/MM/yyyy");

const [fromDate,setFromDate]= React.useState(defaultDate);
const [fromTime,setFromTime]=React.useState(defaultFromTime);
const [toDate, setToDate] = React.useState(defaultDate);
const [toTime,setToTime]=React.useState(defaultToTime);
const [auditData,setAuditData]=useState([]);

const spacingSizeDate=18;
const spacingSize=5.5;

const [open, setOpen] = React.useState(false);
const [message, setMessage] = React.useState("");

const Divider = () => {
    return <hr style={{ borderTop: "1px solid grey" }}></hr>;
  };

const [tabValue, setTabValue] = React.useState(4);

   const handleTabChange = (event, tabValue) => {
        setTabValue(tabValue);
   };

    const handleModalClose = () => {
          setMessage("");
          setOpen(false);
      };

const handleApplicationApi = () => {
       navigate("/applications");

       };

  const viewApplication = () => {

                                  axios.post(viewAppUrl, {
                                           sessionId: sessionId,
                                           applicationName: appName
                                           })
                                         .then(function (response) {
                                           if (response.data) {
                                            navigate("/viewApplication", {state: response.data});
                                            } else {
                                           }
                                         })
                                         .catch(function (error) {
                                            console.log(error);
                                            navigate("/dashboard");
                                  });
     };

    useEffect(()=>{
    	const fetchAuditLogData=async ()=>{

    		await axios.post(auditLogDetails,{
    			  adminSessionId: userSessionId,
    			  application: appName,
                  emailId: emailId.current.value,
                  dateFrom: defaultFromTime,
                  dateTo: defaultToTime,
    		}).then(response=>{
    		if(response.data) {
    		    setAuditData(response.data);
    		}
    		})
    		.catch(error=>{
    		console.error('Error while fetching Audit log data',error);
    	  });
    	  };
        fetchAuditLogData();
       },[]);

    const auditLogsByFilter = () => {

                                  if(fromTime == null || fromDate==null){
                                                  setOpen(true);
                                                  setMessage("Enter From DateTime");

                                        }else if(fromTime.$d == "Invalid Date" || fromDate.$d== "Invalid Date"){
                                                         setOpen(true);
                                                         setMessage("Enter Valid From DateTime");

                                        }else if(toTime == null || toDate==null){
                                                              setOpen(true);
                                                              setMessage("");
                                                              setMessage("Enter To DateTime");
                                        }else if(toTime.$d == "Invalid Date" || toDate.$d== "Invalid Date"){
                                                                setOpen(true);
                                                                setMessage("Enter Valid From DateTime");

                                        }else{
                                        if(fromDate.$D=== undefined && fromDate.$M===undefined && fromDate.$y ===undefined){
                                              appFromDate=fromDate;
                                         }else{
                                              appFromDate=fromDate.$M+1 +"/"+fromDate.$D+"/"+fromDate.$y;
                                             }
                                         if(toDate.$D=== undefined && toDate.$M===undefined && toDate.$y ===undefined){
                                               appToDate=toDate;
                                           }else{
                                              appToDate=toDate.$M+1 +"/"+toDate.$D+"/"+toDate.$y;
                                           }
                                         if(fromTime.$H=== undefined && fromTime.$m===undefined && fromTime.$s ===undefined){
                                                appFromTime=fromTime;
                                         }else{
                                                appFromTime=fromTime.$H +":"+fromTime.$m+":"+fromTime.$s;
                                         }

                                         if(toTime.$H=== undefined && toTime.$m===undefined && toTime.$s ===undefined){
                                                 appToTime=toTime;
                                         }else{
                                                appToTime=toTime.$H +":"+toTime.$m+":"+toTime.$s;
                                         }
                                         }

                                          if (!loading) {
                                                  setSuccess(false);
                                                  setLoading(true);
                                          }
                                    axios.post(auditLogDetails,{
                                        			  adminSessionId: userSessionId,
                                        			  action: action,
                                        			  application: appName,
                                                      emailId: emailId.current.value,
                                                      status: status,
                                                      sessionId: sessionId.current.value,
                                                      dateFrom: appFromDate,
                                                      fromTime: appFromTime,
                                                      dateTo: appToDate,
                                                      toTime: appToTime,
                                        		}).then(response=>{
                                        		if(response.data) {
                                        		   setSuccess(true);
                                                   setLoading(false);
                                        		   setAuditData([]);
                                        		   setAuditData(response.data);
                                        		}
                                    })
                                            .catch(function (error) {
                                               setSuccess(true);
                                               setLoading(false);
                                              console.log(error);
                                     });
        };

function QuickAuditLogSearchToolbar() {
  return (
    <Box className="dataGrid_box" id="application_box" >
      <GridToolbarQuickFilter
             quickFilterParser={(searchInput) =>
               searchInput
                 .split(',')
                 .map((value) => value.trim())
                 .filter((value) => value !== '')
             }
           />
    </Box>
  );
}

  const columns = [
    { field: 'dateTime',headerName: 'Datetime',  flex: 0.7},
    { field: 'action',headerName: 'Action',flex: 0.7},
    { field: 'sessionId',headerName: 'SessionId',flex: 0.7},
    { field: 'email',headerName: 'Email',flex: 0.9},
    { field: 'status',headerName: 'Status',flex: 0.5},
    ];



    return (
     <div>

    <Modal
                  open={open}
                  onClose={handleModalClose}
                  id="modal-head"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 1,
                  }}
                >
                  <Sheet
                    variant="outlined"
                    id="modal-sheet-head"
                    sx={{
                      maxWidth: 300,
                      minWidth: 200,
                      borderRadius: "lg",
                      p: 3,
                      boxShadow: "lg",
                      border: 1.5,
                    }}
                  >
                    <ModalClose
                      variant="solid"
                      id="modal-close"
                      sx={{
                        m: 1,
                        border: 1,
                        size: "lg",
                        bgcolor: "red",
                        justifyContent: "center",
                        margin: -.5,
                      }}
                    />
                    <Panel
                      id="modal-Panel"
                      shaded
                      bordered
                      style={{
                        marginTop: "12%",
                        backgroundColor: "white",
                      }}
                    >
                      <div id="modal-title" class="modal-title">
                        <span id="error-message">{configData.errorTittle}</span>
                      </div>
                      <Divider />
                      <div id="modal-desc" class="modal-desc">
                        <span id="message">{message}</span>
                      </div>
                    </Panel>
                    <FlexboxGrid id="modal-flexboxgrid"justify="center" style={{ margin: 15 }}>
                      <Button
                        variant="contained"
                        color="error"
                        id="closeModal-btn"
                        onClick={handleModalClose}
                        fullWidth="true">
                        {configData.closeButton}
                      </Button>
                    </FlexboxGrid>
                  </Sheet>
                </Modal>
             <Paper square>
                                                                <Tabs
                                                                  value={tabValue}
                                                                  onChange={handleTabChange}
                                                                  variant="plain"
                                                                  color="neutral">
                                                                   <Tab style={appNameStyle} label={appName} />
                                                                   <Tab style={formTab} className="navigation-title" onClick={handleApplicationApi} label="Applications" />
                                                                   <Tab style={formTab} className="navigation-title" onClick={viewApplication} label="Details" />
                                                                   <Tab style={formTab} className="navigation-title" label="Roles" />
                                                                   <Tab style={formTab} className="navigation-title" label="Audit Logs" />
                                                                   <Tab style={formTab} className="navigation-title" label="User" />
                                                                </Tabs>
                                                              </Paper>
                           <br/>
                            <Box id="form-box" className="audit-log-form-box">

                            <Stack spacing={spacingSize} direction="row" className="stack_textField">

                                        <Autocomplete className="auto-complete"
                                                      onChange={(event, actionValue) => setAction(actionValue)}
                                                      id="action"
                                                      options={actionData.map((option) => option.value)}
                                                      renderInput={(params) => <TextField {...params}
                                                      label="Action"
                                                      placeholder="Select Action"
                                                      InputProps={{ ...params.InputProps,
                                                                 startAdornment: ( <InputAdornment position="start">   <HistoryIcon />
                                                                                        </InputAdornment> ),
                                                                 disableUnderline: true
                                                                  }} />}

                                        />
                                       <TextField id="email-Id" className="auto-complete"
                                                                      type="text"
                                                                      inputRef={emailId}
                                                                      variant='outlined'
                                                                      color='secondary'
                                                                      label="Email Id" />
                                        <TextField id="sessionId" className="auto-complete"
                                                                 type="text"
                                                                 inputRef={sessionId}
                                                                 variant='outlined'
                                                                 color='secondary'
                                                                 label="Session Id"  />
                            </Stack>


                             <LocalizationProvider id="local-provider" dateAdapter={AdapterDayjs}>
                                                 <DemoContainer id="demo-container" components={['DateTimePicker','TimeField']}>
                                                    <Stack  spacing={spacingSizeDate} direction="row" className="stack_textField">
                                                        <DatePicker  id="from-date" sx={dateTimeStyle}  format="DD/MM/YYYY"
                                                                     value={dayjs(fromDate)} label={'From Date'}
                                                                     views={['year', 'month', 'day']} disableFuture required
                                                                     onChange={(fromDateValue) => {
                                                                             setFromDate(fromDateValue);
                                                                            }}
                                                                    slotProps={{
                                                                                 textField: {
                                                                                             helperText: 'DD/MM/YYYY',
                                                                                             id: "from_date"
                                                                                },
                                                                   }}
                                                        />
                                                        <TimePicker id="from-time" sx={dateTimeStyle}
                                                                    ampm={false}
                                                                    openTo="hours"
                                                                    views={['hours', 'minutes']}
                                                                    inputFormat="HH:mm"
                                                                    mask="__:__"
                                                                    label="GMT"
                                                                    value={dayjs(fromTime)}
                                                                    onChange={(fromTimeValue) => {
                                                                         setFromTime(fromTimeValue);
                                                                         }}
                                                                    slotProps={{
                                                                            textField: {
                                                                                        helperText: 'HH:MM',
                                                                                        id: "from_time"
                                                                                        },
                                                                           }}
                                                        />
                                                         <Autocomplete className="auto-complete"
                                                                       onChange={(event, statusValue) => setStatus(statusValue)}
                                                                       id="status"
                                                                       options={statusData.map((option) => option.value)}
                                                                       renderInput={(params) => <TextField {...params}
                                                                       label="status"
                                                                       placeholder="Select status"
                                                                       InputProps={{ ...params.InputProps,
                                                                       startAdornment: ( <InputAdornment position="start">
                                                                                            <HistoryIcon />
                                                                                          </InputAdornment> ),
                                                                       disableUnderline: true
                                                                       }} />}
                                                          />
                                                        </Stack>
                                                         <Stack style={{marginTop:'-4px'}} id="stack-row-third" spacing={spacingSizeDate} direction="row" className="stack_textField">
                                                         <DatePicker id="to-date" sx={dateTimeStyle}
                                                                                  format="DD/MM/YYYY"
                                                                                  value={dayjs(toDate)}
                                                                                  label={'To Date'}
                                                                                  openTo="day"
                                                                                  required
                                                                                  onChange={(toDateValue) => {
                                                                                            setToDate(toDateValue);
                                                                                   }}
                                                                                  slotProps={{
                                                                                             textField: {
                                                                                                         helperText: 'DD/MM/YYYY',
                                                                                                         id: "to_date"
                                                                                                        },
                                                                 }}  />

                                                                 <TimePicker required id="to-time"
                                                                                      sx={dateTimeStyle}
                                                                                      ampm={false}
                                                                                      openTo="hours"
                                                                                      views={['hours', 'minutes']}
                                                                                      inputFormat="HH:mm"
                                                                                      mask="__:__"
                                                                                      label="GMT"
                                                                                      value={dayjs(toTime)}
                                                                                      onChange={(toTimeValue) => {
                                                                                             setToTime(toTimeValue);
                                                                                            }}
                                                                                     slotProps={{
                                                                                                 textField: {
                                                                                                              helperText: 'HH:MM',
                                                                                                              id: "to_time"
                                                                                               },
                                                                         }}
                                                                         />
                                                </Stack>
                                                 </DemoContainer>
                                             </LocalizationProvider>

                                           <Stack  spacing={2} direction="row" style={{marginLeft: '29%',marginTop: '1%'}}>

                                           <Button id="audit-btn" onClick={auditLogsByFilter} style={{background:'red', color: 'white',alignSelf: 'center'}}
                                                                   variant="contained"
                                                                   > Search
                                                           </Button>

                                             <Button id="audit-btn" style={{background:'grey', color: 'white',alignSelf: 'center'}}
                                                                                                              variant="contained"
                                                                                                              > Cancel
                                            </Button>

                                            {loading && (
                                                                                                                    <CircularProgress
                                                                                                                      size={60}
                                                                                                                      sx={{
                                                                                                                        color: 'grey',
                                                                                                                        position: 'absolute',
                                                                                                                        top: '74%',
                                                                                                                        left: '37%',
                                                                                                                      }}
                                                                                                                    />
                                                                                                                  )}


                                           </Stack>
                                             <br/>

                                              <Box
                                                                sx={{
                                                                  display: 'flex',
                                                                  flexWrap: 'wrap-reverse',
                                                                  p: 1,
                                                                  m: 1,
                                                                  bgcolor: 'background.paper',
                                                                  borderRadius: 1,
                                                                  border: '1px solid',
                                                                }}
                                                              >
                                             <div class="scroll">
             <DataGrid id="application_dataGrid" sx={{
                                                                '.MuiDataGrid-columnHeaderTitle': {
                                                                   fontWeight: 'bold !important',
                                                                   overflow: 'visible !important',
                                                                },
                                                                '.MuiDataGrid-rowHeaderTitle': {
                                                                                  backgroundColor: "#99D9EA"
                                                                               },
                                                                boxShadow: 1,
                                                                    border: 2,
                                                                    borderColor: 'primary.light',
                                                                    '& .MuiDataGrid-cell:hover': {
                                                                      color: 'primary.main',
                                                                    },
                                                              }}

                    columns={columns}
                    getRowId={(auditData) => auditData.recordId}
                     initialState={{
                                               pagination: { paginationModel: { pageSize: 100 } },
                      }}
                      pageSizeOptions={[ 10, 25,100]}
                      rows={auditData}
                            slots={{ toolbar: QuickAuditLogSearchToolbar }}
                               disableColumnSelector
                               disableDensitySelector
                  />
                  </div>
                  </Box>
                  </Box>
     </div>
    );
};

export default AuditLog;