import * as React from 'react';
import {useState,useEffect} from "react";
import Avatar from '@mui/joy/Avatar';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Box from '@mui/material/Box';
import { InputLabel,MenuItem ,Select,TextField, Button, Container, Stack } from '@mui/material';
import "../assets/css/Content.css";
import InputAdornment from "@mui/material/InputAdornment";
import AppsIcon from '@mui/icons-material/Apps';
import HistoryIcon from '@material-ui/icons/History';
import GroupIcon from '@material-ui/icons/Group';
import SaveIcon from '@material-ui/icons/Save';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { configData } from "../config/config-dev";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import {Panel,FlexboxGrid} from "rsuite";
import Tooltip from '@mui/material/Tooltip';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


export default function UserOperation() {

    const [internal, setInternal] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const navigate=useNavigate();
    const [userType, setUserType] = React.useState("");
    const getApplicationUrl = configData.gateWayUrl + configData.applicationForAdmin;
    const getRoleUrl = configData.gateWayUrl + configData.getRole;
    const reportUrl = configData.gateWayUrl + configData.userReportApi;
    const sessionId = sessionStorage.getItem('SessionId');
    const [appData,setAppData]=useState([]);
    const [roleData,setRoleData]=useState([]);

    const [alertOpen,setAlertOpen]=useState(false);
    const [alertMessage,setAlertMessage]=useState('');
    const [alertSeverity,setAlertSeverity]=useState('success');
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [reportFormat, setReportFormat] = React.useState("");
    const [application, setApplication] = React.useState("");

    const firstnameRef=React.useRef(0);
    const surnameRef=React.useRef(0);
    const emailRef=React.useRef(0);
    const formatRef=React.useRef(0);
    const companyRef=React.useRef(0);
    const reportFormatData=JSON.parse(configData.reportFormat);
    const internalData=configData.internalYesNo;

    const formTab={textTransform: 'none',fontSize: '100%',minWidth: 90,'font-weight': 'bold'};
    const [tabValue, setTabValue] = React.useState(0);

     const handleAuditApi = () => {
             navigate("/auditReport");
       }
     const handleReset = (e) => {
               firstnameRef.current.value = "";
               surnameRef.current.value = "";
               emailRef.current.value = "";
               companyRef.current.value = "";
               setInternal("");
               setRoleData("");
               setRoleData([]);
               setApplication("");

      };

    const handleTabChange = (event, tabValue) => {
            setTabValue(tabValue);
    };

    const handleModalClose = () => {
       setMessage("");
       setOpen(false);
   };
    const handleAlertClose=(event,reason)=>{
        if(reason=='clickaway'){
            return;
            }
        setAlertOpen(false);
        };

 useEffect(()=>{
 	const fetchData=async ()=>{

 		await axios.post(getApplicationUrl,{
 			  sessionId: sessionId
 		}).then(response=>{
 		if(response.data) {
 		    setAppData(response.data);
 		}
 		})
 		.catch(error=>{
 		console.error('Error while fetching application',error);
 	  });
 	  };
     fetchData();
    },[]);

      const handleAppChange = (event, appId) => {
           if(appId!=null){
           setApplication(appId);
           	const fetchData= ()=>{
           		 axios.post(getRoleUrl,{
           			  sessionId: sessionId,
           			  appId: appId

           		}).then(response=>{
           		setRoleData(response.data);
           		})
           		.catch(error=>{
           		console.error('Error while fetching Roles',error);
           	  });
           	  };
               fetchData();
      }
      };
  const handleReporting=()=>{

     if(application=== undefined || application=="" || application== null){
            setOpen(true);
            setMessage("Select a application");
     }
     else if(formatRef.current.value=== undefined || formatRef.current.value=="" || formatRef.current.value== null){
                      setOpen(true);
                      setMessage("Select Report Format");
     }
     else {
      if (!loading) {

                         setLoading(true);
               }
      const fetchData= ()=>{

                 		 axios.post(reportUrl,{
                 			  sessionId: sessionId,
                 			  appId: application,
                 			  userFilter: {
                                    firstname: firstnameRef.current.value,
                                    surname: surnameRef.current.value,
                                    email: emailRef.current.value,
                                    company: companyRef.current.value,
                                    internal: internal,
                                    roleType: userType,
                                    reportType: "user",
                                    pageSize: "1000"
                               },
                              format: formatRef.current.value,
                 		}).then(response=>{
                 		    setSuccess(true);
                            setLoading(false);
                           if (response.data.statusCode==200) {
                                setAlertMessage(response.data.message);
                                setAlertSeverity('success');
                                setAlertOpen(true);
                           } else {
                                setSuccess(true);
                                setLoading(false);
                                setAlertMessage(response.data.message);
                                setAlertSeverity('error');
                                setAlertOpen(true); ;
                           }
                 		})
                 		.catch(error=>{
                 		console.error('Error while creating reports',error);
                 		setAlertMessage('Request failed, try again later');
                        setAlertSeverity('error');
                        setAlertOpen(true);
                        setLoading(false);
                 	  });

                 	  };
                     fetchData();
                     }
      };

  const handleReportFormatChange = (e) => {
          setReportFormat(e.target.value);
  };
  const handleInternalChange = (e) => {
          setInternal(e.target.value);
  };



  return (
  <div>
   <Modal
                open={open}
                onClose={handleModalClose}
                id="modal-head"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: 1,
                }}
              >
                <Sheet
                  variant="outlined"
                  id="modal-sheet-head"
                  sx={{
                    maxWidth: 300,
                    minWidth: 200,
                    borderRadius: "lg",
                    p: 3,
                    boxShadow: "lg",
                    border: 1.5,
                  }}
                >
                  <ModalClose
                    variant="solid"
                    id="modal-close"
                    sx={{
                      m: 1,
                      border: 1,
                      size: "lg",
                      bgcolor: "red",
                      justifyContent: "center",
                      margin: -.5,
                    }}
                  />
                  <Panel
                    id="modal-Panel"
                    shaded
                    bordered
                    style={{
                      marginTop: "12%",
                      backgroundColor: "white",
                    }}
                  >
                    <div id="modal-title" class="modal-title">
                      <span id="error-message">{configData.errorTittle}</span>
                    </div>
                    <Divider />
                    <div id="modal-desc" class="modal-desc">
                      <span>{message}</span>
                    </div>
                  </Panel>
                  <FlexboxGrid id="modal-flexboxgrid"justify="center" style={{ margin: 15 }}>
                    <Button
                      variant="contained"
                      color="error"
                      id="closeModal"
                      onClick={handleModalClose}
                      fullWidth="true"
                    >
                      {configData.closeButton}
                    </Button>
                  </FlexboxGrid>
                </Sheet>
              </Modal>
             <Paper square>
                          <Tabs
                                 value={tabValue}
                                 onChange={handleTabChange}
                                 variant="plain"
                                 color="neutral">
                                 <Tab style={formTab}  label="User Report"  />
                                 <Tab style={formTab}  className="navigation-title" onClick={handleAuditApi} label="Audit Report" />

                          </Tabs>
             </Paper>
             <br/>

  <Box id="user-form-box"  className="user_form_box">
     <div id="audit-form-div" className="audit_form_div">
      <Stack id="stack-row-first" spacing={12} direction="row" className="stack_textField" >
                                  <Autocomplete required
                                    value={application}
                                    onChange={handleAppChange}
                                    id="combo-box-demo"
                                    options={appData.map((option) => option.applicationName)}
                                    className="auto-complete"
                                    renderInput={(params) => <TextField {...params}
                                                              label="Application"
                                                              required
                                    placeholder="Search Application"
                                    InputProps={{ ...params.InputProps,
                                                   startAdornment: ( <InputAdornment position="start"> <AppsIcon />
                                                                   </InputAdornment> ),
                                                   disableUnderline: true
                                                }} />}

                                  />
  <TextField className="auto-complete"
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="Email Id"
                                inputRef={emailRef}
                                id="email-Id"

                                />

  </Stack>
  <Stack id="stack-row-second" spacing={12} direction="row" className="stack_textField">
                         <TextField className="auto-complete"
                                type="text"
                                variant='outlined'
                                color='secondary'
                                label="First Name"
                                inputRef={firstnameRef}
                                id="first-name"
                                />

                          <TextField id="last-name" className="auto-complete"
                                                       type="text"
                                                       variant='outlined'
                                                       color='secondary'
                                                       label="Last Name"
                                                       inputRef={surnameRef}
                                                       />
                     </Stack>

   <Stack id="stack-row-third"  spacing={12} direction="row" className="stack_textField">
       <TextField className="auto-complete"
                                         type="text"
                                         variant='outlined'
                                         color='secondary'
                                         label="Company"
                                         inputRef={companyRef}
                                         id="company-name"
                                         />
                                     <Autocomplete className="auto-complete"
                                                               onChange={(event, internalValue) => setInternal(internalValue)}
                                                               id="internal"
                                                               value={internal}
                                                               options={internalData.map((option) => option.value)}
                                                               renderInput={(params) => <TextField {...params}
                                                               label="Internal(Vodafone/Internal User)"
                                                               placeholder="Select Internal"
                                                               InputProps={{ ...params.InputProps,
                                                                          startAdornment: ( <InputAdornment position="start">   <GroupIcon />
                                                                                                 </InputAdornment> ),
                                                                          disableUnderline: true
                                                                           }} />}

                                                               />


    </Stack>
         <Stack  id="stack-row-fourth" spacing={12} direction="row"  className="stack_textField">
                               <TextField select required className="auto-complete"
                                                                label="Report Format"
                                                                value={reportFormat}
                                                                variant="outlined"
                                                                color='secondary'
                                                                id="report-format"
                                                                inputRef={formatRef}
                                                                onChange={handleReportFormatChange}
                                                                SelectProps={{
                                                                    native: true,
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SaveIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            >
                                                                {reportFormatData.map((reportFormatList) => (
                                                                    <option key={reportFormatList.value} value={reportFormatList.value}>
                                                                        {reportFormatList.value}
                                                                    </option>
                                                                ))}
                                                                </TextField>
                                                                 <Autocomplete
                                                                   className="auto-complete"
                                                                   value={userType}
                                                                   id="role-type"
                                                                   onChange={(event, roleTypeChange) => setUserType(roleTypeChange)}
                                                                   options={roleData.map((option) => option)}
                                                                   renderInput={(params) => <TextField {...params}
                                                                               label="Role Type"
                                                                               placeholder="Select Role Type"
                                                                               InputProps={{ ...params.InputProps,
                                                                                                startAdornment: ( <InputAdornment position="start"><GroupIcon />
                                                                                                                 </InputAdornment> ),
                                                                                                                  disableUnderline: true
                                                                                           }} />}

                                                                                                                                   />

                           </Stack>
        <Stack id="stack-row-fifth"  spacing={2} direction="row"  className="audit_stack_button">
         <Tooltip id="btn-tooltip" title={configData.reportingMessage}>
        <Button id="user-sbt-btn" style={{background:'red'}} variant="contained" onClick={handleReporting} >Generate Report</Button>
        </Tooltip>
        <Button id="user-cancel-btn"  onClick={handleReset} style={{background:'grey'}}  variant="contained">Reset</Button>
         {loading && (
                                                                                <CircularProgress
                                                                                  size={40}
                                                                                  sx={{
                                                                                    color: 'grey',
                                                                                    position: 'absolute',
                                                                                    top: '94%',
                                                                                    left: '50%',
                                                                                  }}
                                                                                />
                                                                              )}

      </Stack>
      <Snackbar id="snack-bar" open={alertOpen} autoHideDuration={1000000} onClose={handleAlertClose}>
              <Alert id="mui-alert" onClose={handleAlertClose} severity={alertSeverity} sx={{width: '100%'}}>{alertMessage}</Alert>
      </Snackbar>
</div>
      </Box>
    </div>
  );
}
