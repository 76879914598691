import React, {useState,useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const ErrorPage = () => {

const location = useLocation();
const errMessage = location.state;

return (
      <div>
       <p> {errMessage.message}</p>
      </div>
    );
};

export default ErrorPage;


