import React from "react";
import Contents from "../components/Contents";
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import CdbSideBar from "../components/CdbSideBar";
import { useNavigate } from 'react-router-dom';
import { InputLabel,MenuItem ,Select,TextField, Container, Stack } from '@mui/material';
import '../assets/css/Reporting.css';
import ButtonGroup from '@mui/material/ButtonGroup';

import Tooltip from '@mui/material/Tooltip';
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import {Table,TableBody,TableCell,TableRow} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import axios from "axios";
import { configData } from "../config/config-dev";



const Reporting = () => {

const sidebarTd={ paddingLeft: '0.0%',paddingTop: '0.0%',width: '10%',verticalAlign: 'top', textAlign: 'left'};
const contentsTd={ paddingLeft: '0.0%',paddingTop: '0.0%',verticalAlign: 'top', textAlign: 'left'};


const navigate=useNavigate();
const handleUser = () => {
navigate("/userReport");
}
const handleAudit = () => {
                  navigate("/auditReport");
}
  return (

  <div>
    <Header/>
    <div>
           <Table>
           <TableRow>
               <TableCell style={sidebarTd}>
        <CdbSideBar/>
        </TableCell>
        <TableCell style={contentsTd}>

            <div className="container">

                      <div className="button-group">

                        <Tooltip title="Click to get all the filters to generate User Report for an application ">
                         <Card sx={{ maxWidth: 445 }}>
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="260"
                                  src={require('./../assets/images/users.png')}
                                  alt="User Report"
                                  onClick={handleUser}
                                />
                                <CardContent>
                                  <Typography gutterBottom variant="h5" component="div">
                                    User Report
                                  </Typography>
                                  <Typography variant="body1" color="text.secondary">
                                    Click to get all the filters to generate reports for all the users in the application,
                                    all filters can be used here to generate a user report.
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                              <CardActions>
                                <Button size="small" color="primary" onClick={handleUser}>
                                  Generate
                                </Button>
                              </CardActions>
                            </Card>
                        </Tooltip>
                        <Tooltip title="Click to get all the filters to generate Audit logs for an application">
                        <Card sx={{ maxWidth: 445 }}>
                                                      <CardActionArea>
                                                        <CardMedia
                                                          component="img"
                                                          height="260"
                                                          src={require('./../assets/images/audit.jpg')}
                                                          alt="Audit Report"
                                                          onClick={handleAudit}
                                                        />
                                                        <CardContent>
                                                          <Typography gutterBottom variant="h5" component="div">
                                                            Audit Report
                                                          </Typography>
                                                          <Typography variant="body1" color="text.secondary">
                                                            Click to get all the filters to generate Audit logs for an application,
                                                            all filters can be used here to generate a audit report.

                                                          </Typography>
                                                        </CardContent>
                                                      </CardActionArea>
                                                      <CardActions>
                                                        <Button size="small" color="primary" onClick={handleAudit}>
                                                          Generate
                                                        </Button>
                                                      </CardActions>
                                                    </Card>
                        </Tooltip>
                        </div>
                        </div>


        </TableCell>
        </TableRow>
        </Table>
        </div>
    <Footer/>
    </div>

  );
};

export default Reporting;
