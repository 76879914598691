import * as React from 'react';
import {Container,Grid} from '@mui/material';
import MyCard from './MyCard'
import Slider from './Slider'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AppsIcon from '@mui/icons-material/Apps'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import AddTaskIcon from '@mui/icons-material/AddTask'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import {  CardActionArea, CardActions ,Typography,IconButton} from '@mui/material';
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const WelcomePage=()=>{
const appName = sessionStorage.getItem('ApplicationName');
const sessionId = sessionStorage.getItem('SessionId');

const viewApplication = (event, appId) => {

                                 axios.post(viewAppUrl, {
                                          sessionId: sessionId,
                                          applicationName: appName
                                          })
                                        .then(function (response) {
                                          if (response.data) {
                                           sessionStorage.setItem('ApplicationName',response.data.applicationName);
                                           navigate("/viewApplication", {state: response.data});
                                           } else {
                                           navigate("/");
                                          }
                                        })
                                        .catch(function (error) {
                                          navigate("/");
                                 });
    };

    return(
        <Container sx={{marginTop: "3%", marginLeft: "-3%"}}>
            <Grid container spacing={8}>
              <Grid item xs={10} md={9} >
                  <Slider/>
              </Grid>

              <Grid  item xs={12} md={12} sx={{position: 'relative' }} >
                 <Grid Container  spacing={1} sx={{marginLeft: "73%" ,marginTop: "-43%",bgcolor: 'rgba(0,0,0,0.1)',position: 'absolute',paddingTop: "3%",
                            borderRadius: "2%",paddingBottom: "2%",
                                                            }} >
                    <Typography sx={{color: "Black",paddingLeft: "3%"}} gutterBottom variant="h6" component="div">
                                        Quick Links for {appName}
                    </Typography>
                     <Grid Container sx={{display: "flex"}} spacing={1}  >
                     <Grid item xs={12} md={12}>
                     <MyCard title="Application Details"  icon={<AppsIcon/> } path="/viewApplication" iconColor="white" cardColor="#057ea3"/>
                     </Grid>
                     <Grid item xs={12} md={12}>
                     <MyCard title="App Users" icon={<PeopleAltIcon/>} path="/getUserApplication" iconColor="white" cardColor="#8BC34A"/>
                     </Grid>
                     </Grid>
                     <Grid Container  sx={{display: "flex"  }} spacing={1}  >
                     <Grid item xs={12} md={12}>
                     <MyCard title="Audit logs" icon={<PendingActionsIcon/>} path="/auditLog" iconColor="white" cardColor="red"/>
                     </Grid>
                     <Grid item xs={12} md={12}>
                     <MyCard title="Roles" icon={<FolderSharedIcon/>} path="/dashboard" iconColor="white" cardColor="#FF9800"/>
                     </Grid>
                     </Grid>
                 </Grid>
              </Grid>
              <Grid item xs={10} md={7} sx={{marginTop: "-8%"}}>
                             <Grid Container sx={{display: "flex"}}  >
                                 <Grid item xs={12} md={6}>
                                    <MyCard title="Administrator"  icon={<AdminPanelSettingsIcon/> } path="/administrator" iconColor="white" cardColor="#ad0000"/>
                                 </Grid>
                                 <Grid item xs={12} md={12}>
                                     <MyCard title="Role Approval"  icon={<AddTaskIcon/> } path="/dashboard" iconColor="white" cardColor="#ad0000"/>
                                 </Grid>
                                 <Grid item xs={12} md={12}>
                                     <MyCard title="Sessions"  icon={<VpnKeyIcon/> } path="/sessions" iconColor="white" cardColor="#ad0000"/>
                                 </Grid>
                                 <Grid item xs={12} md={12}>
                                     <MyCard title="Reporting"  icon={<AnalyticsIcon/> } path="/reporting" iconColor="white" cardColor="#ad0000"/>
                                 </Grid>
                                <Grid item xs={12} md={12}>
                                    <MyCard title="Bulk Operations"  icon={<UploadFileIcon/> } path="/upload" iconColor="white" cardColor="#ad0000"/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <MyCard title="All Users"  icon={<PeopleAltIcon/> } path="/userdetails" iconColor="white" cardColor="#ad0000"/>
                                </Grid>
                             </Grid>
                            </Grid>
            </Grid>
         </Container>
        );
    }
export default WelcomePage;