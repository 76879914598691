import React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@material-ui/core/Divider';


const SubmitBtn = ({ label, onClick,variant }) => {

  return (
    <Button   variant={variant}  onClick={onClick} >
      {label}
    </Button>
  );
};

const CancelBtn = ({ label, onClick,variant }) => {
  return (
    <Button   variant={variant}  onClick={onClick} >
      {label}
    </Button>
  );
};

const LineSeparator = () => {

  return (

 <Divider style={{backgroundColor: "#fff", height: '2px'}}/>
 );
 };

const InputBox = ({ value, placeholder, type,onChange }) => {

     return (
      <TextField type={type} value={value}  placeholder={placeholder} onChange={onChange} />
     );
}
const CustomCheckBox = ( {checkboxValue, checkboxLabel, checked }) => {
     return (
       <FormControlLabel
         control={
           <Checkbox
             checked={checked}
             onChange={this.handleCheckboxChange}
             value={checkboxValue}
           />
         }
         label={checkboxLabel}
       />
     )
}


const CustomTextArea = ({ name, placeholder, type,onChange }) => {

        return (
       <Textarea name={name} type={type} placeholder={placeholder} variant="soft" />
        );
}


  const SelectDropDown = ({ id ,value,label,onChange }) => {

          return (
         <Select
                  id={id}
                  value={value}
                  label={label}
                  onChange={onChange}>
                </Select>
          );
        }


export { SubmitBtn,CancelBtn, CustomCheckBox,InputBox,CustomTextArea,SelectDropDown,LineSeparator};