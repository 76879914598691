import  React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "../assets/css/Content.css";

 const Content = () => {

   return (
    <div>
    </div>

   );
 };

 export default Content;