import React, {useState,useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { configData } from "../config/config-dev";
import { useNavigate } from 'react-router-dom';


const RedirectUrl = () => {

  const navigate=useNavigate();
  const location = useLocation();
  const allowDomainUrl = configData.gateWayUrl + configData.allowDomain;
  const queryParams = new URLSearchParams(location.search);
  const appName=queryParams.get('aaa:app');
  const requestUrlDomain=queryParams.get('aaa:request');
  const requestData={appName: appName,redirectUrl: requestUrlDomain }

  const ErrorMessage={message: 'Unauthorized Access'  }


    useEffect(()=> {

     if(requestUrlDomain!=null && appName!=null) {
        if(appName=="Admin") {
            navigate("/");
        }else{
            const requestUrl=requestUrlDomain.substring(requestUrlDomain.indexOf('/')+1);
            const allowDomainByApp=async ()=>{
      		await axios.get(allowDomainUrl, {
                                       params: {
                                          appName: appName,
                                          requestDomainUrl: requestUrl,
                                        }
                                       }).then(response=>{
      	                                 	if(response.data) {
                                             navigate("/", { state: requestData });
      	                                	}
      	                                	else{
                                             navigate("/unauthorized", { state: ErrorMessage });
      	                                	}
      	                            	})
      	                            	.catch(error=>{
      	                                	console.error('Error ',error);
                                            navigate("/unauthorized", { state: ErrorMessage });
                                    	  });
                                   	  };
                  allowDomainByApp();
                  }
                  }else {
                     navigate("/unauthorized", { state: ErrorMessage });
                  }
                   },[]);

    return (
      <div>

      </div>
    );
};
export default RedirectUrl;