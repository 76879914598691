import React, {useState,useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import {TextField, Button, Stack } from '@mui/material';
import "../assets/css/Content.css";
import InputAdornment from "@mui/material/InputAdornment";
import AppsIcon from '@mui/icons-material/Apps';
import HistoryIcon from '@material-ui/icons/History';
import SaveIcon from '@material-ui/icons/Save';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import { configData } from "../config/config-dev";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import {Panel,FlexboxGrid} from "rsuite";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function AuditReport() {

const navigate=useNavigate();

const Divider = () => {
    return <hr style={{ borderTop: "1px solid grey" }}></hr>;
  };

const [alertSeverity,setAlertSeverity]=useState('success');
const [loading, setLoading] = React.useState(false);
const spacingSizeDate=22.5;
const spacingSize=10;
const errorMessage="Unable to process the generate report";

const [open, setOpen] = React.useState(false);
const [message, setMessage] = React.useState("");
var appFromDate,appToDate,appFromTime,appToTime;

const [success, setSuccess] = React.useState("");
const [alertOpen,setAlertOpen]=useState(false);
const [application, setApplication] = React.useState("");
const [action, setAction] = React.useState("");
const [reportFormat, setReportFormat] = React.useState("");
const emailId= useRef('');
const [appData,setAppData]=useState([]);

const defaultToTime=new Date().toLocaleString("en-US", {timeZone: "GMT"});
const defaultFromTime= new Date(Date.now() - 1 * (60 * 60 * 1000)).toLocaleString("en-US", {timeZone: "GMT"});
const defaultDate=new Date().toLocaleDateString("en-US", {timeZone: "GMT"},{ year: 'numeric', day: '2-digit', month: '2-digit' }).toString("dd/MM/yyyy");

const [fromDate,setFromDate]= React.useState(defaultDate);
const [fromTime,setFromTime]=React.useState(defaultFromTime);
const [toDate, setToDate] = React.useState(defaultDate);
const [toTime,setToTime]=React.useState(defaultToTime);

 const handleReset = (e) => {
               emailId.current.value = "";
               setApplication("");
               setAction("");
               setFromDate(defaultDate);
               setFromTime(defaultFromTime);
               setToDate(defaultDate);
               setToTime(defaultToTime);

      };

const auditReportUrl = configData.gateWayUrl + configData.auditReportApi;
const getApplicationUrl = configData.gateWayUrl + configData.applicationForAdmin;
const sessionId = sessionStorage.getItem('SessionId');
const reportFormatData=JSON.parse(configData.reportFormat);
const actionData=JSON.parse(configData.actionType);
const [alertMessage,setAlertMessage]=useState('');
const formatRef=React.useRef(0);
const formTab={textTransform: 'none',fontSize: '14px',minWidth: 90,'font-weight': 'bold'};
const [tabValue, setTabValue] = React.useState(0);

   const handleTabChange = (event, tabValue) => {
        setTabValue(tabValue);
   };
const handleAlertClose=(event,reason)=>{
        if(reason=='clickaway'){
            return;
            }
        setAlertOpen(false);
};
 const handleModalClose = () => {
       setMessage("");
       setOpen(false);
   };

 const handleUserApi = () => {
      navigate("/userReport");
 }
useEffect(()=>{
 	const fetchData=async ()=>{

 		await axios.post(getApplicationUrl,{
 			  sessionId: sessionId
 		}).then(response=>{
 		if(response.data) {
 		    setAppData(response.data);
 		}
 		})
 		.catch(error=>{
 		console.error('Error while fetching application',error);
 	  });
 	  };
     fetchData();
    },[]);

    const handleReportFormatChange = (e) => {
             setReportFormat(e.target.value);
     };

   const handleApplicationChange = (event, value) => {
              setApplication(value);
   };

const generateAuditReport = () => {

  const sessionId = sessionStorage.getItem('SessionId');
  const fromMonth = new Date(fromDate).getMonth();
  const fromYear = new Date(fromDate).getFullYear();
  const toMonth = new Date(toDate).getMonth();
  const toYear = new Date(toDate).getFullYear();
  const fromDateMillis = new Date(fromDate).getTime();
  const toDateMillis = new Date(toDate).getTime();
  const fromTimeMillis = new Date(fromTime).getTime();
  const toTimeMillis = new Date(toTime).getTime();



      if (application === "" || application== null ) {
            setOpen(true);
            setMessage("Select a application");
      }
      else if (formatRef.current.value === "" || formatRef.current.value== null) {
                  setOpen(true);
                  setMessage("Select report format");


      }else if(fromTime == null || fromDate==null){
                setOpen(true);
                setMessage("Enter From DateTime");

      }else if(fromTime.$d == "Invalid Date" || fromDate.$d== "Invalid Date"){
                       setOpen(true);
                       setMessage("Enter From DateTime");

      }else if(toTime == null || toDate==null){
                            setOpen(true);
                            setMessage("");
                            setMessage("Enter To DateTime");
      }else if(toTime.$d == "Invalid Date" || toDate.$d== "Invalid Date"){
                   setAlertOpen(false);
                   setOpen(true);
                   setMessage("Enter From DateTime");
      }else if (fromDateMillis > toDateMillis) {
                          setAlertOpen(false);
                          setOpen(true);
                          setMessage('From Date cannot be after To Date.');
      }else if (fromDateMillis === toDateMillis && fromTimeMillis > toTimeMillis) {
                   setAlertOpen(false);
                   setOpen(true);
                   setMessage('From time cannot be after To Time.');
      }else {
      if(fromDate.$D=== undefined && fromDate.$M===undefined && fromDate.$y ===undefined){
            appFromDate=fromDate;
       }else{
            appFromDate=fromDate.$M+1 +"/"+fromDate.$D+"/"+fromDate.$y;
           }
       if(toDate.$D=== undefined && toDate.$M===undefined && toDate.$y ===undefined){
             appToDate=toDate;
         }else{
            appToDate=toDate.$M+1 +"/"+toDate.$D+"/"+toDate.$y;
         }
       if(fromTime.$H=== undefined && fromTime.$m===undefined && fromTime.$s ===undefined){
              appFromTime=fromTime;
       }else{
              appFromTime=fromTime.$H +":"+fromTime.$m+":"+fromTime.$s;
       }

       if(toTime.$H=== undefined && toTime.$m===undefined && toTime.$s ===undefined){
               appToTime=toTime;
       }else{
              appToTime=toTime.$H +":"+toTime.$m+":"+toTime.$s;
       }
         if (!loading) {
                   setSuccess(false);
                   setLoading(true);
         }

       axios.post(auditReportUrl, {
                 userSessionId: sessionId,
                 applicationName: application,
                 emailId: emailId.current.value,
                 action: action,
                 fromDate: appFromDate,
                 fromTime: appFromTime,
                 toDate: appToDate,
                 toTime: appToTime,
                 reportFormat: formatRef.current.value,
                 })
               .then(function (response) {
                 setSuccess(true);
                 setLoading(false);
                 if (response.data.statusCode==200) {
                    setAlertMessage(response.data.message);
                    setAlertSeverity('success');
                    setAlertOpen(true);
                 } else {
                      setAlertMessage(errorMessage);
                      setAlertSeverity('error');
                      setAlertOpen(true);
                 }
               })
               .catch(function (error) {
                 console.log(error);
                 setSuccess(true);
                 setLoading(false);
                 setOpen(true);
                 setMessage("");
                 setMessage(errorMessage);
               });
            }
           };



  return (
  <div>
   <Modal
              open={open}
              onClose={handleModalClose}
              id="modal-head"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
              }}
            >
              <Sheet
                variant="outlined"
                id="modal-sheet-head"
                sx={{
                  maxWidth: 300,
                  minWidth: 200,
                  borderRadius: "lg",
                  p: 3,
                  boxShadow: "lg",
                  border: 1.5,
                }}
              >
                <ModalClose
                  variant="solid"
                  id="modal-close"
                  sx={{
                    m: 1,
                    border: 1,
                    size: "lg",
                    bgcolor: "red",
                    justifyContent: "center",
                    margin: -.5,
                  }}
                />
                <Panel
                  id="modal-Panel"
                  shaded
                  bordered
                  style={{
                    marginTop: "12%",
                    backgroundColor: "white",
                  }}
                >
                  <div id="modal-title" class="modal-title">
                    <span id="error-message">{configData.errorTittle}</span>
                  </div>
                  <Divider />
                  <div id="modal-desc" class="modal-desc">
                    <span id="message">{message}</span>
                  </div>
                </Panel>
                <FlexboxGrid id="modal-flexboxgrid"justify="center" style={{ margin: 15 }}>
                  <Button
                    variant="contained"
                    color="error"
                    id="closeModal-btn"
                    onClick={handleModalClose}
                    fullWidth="true">
                    {configData.closeButton}
                  </Button>
                </FlexboxGrid>
              </Sheet>
            </Modal>
            <Paper square>
                               <Tabs
                                                                              value={tabValue}
                                                                              onChange={handleTabChange}
                                                                              variant="plain"
                                                                              color="neutral">
                                                                               <Tab style={formTab}  label="Audit Report" />
                                                                               <Tab style={formTab}  className="navigation-title" onClick={handleUserApi} label="User Report" />
                                                                            </Tabs>
                                                                          </Paper>
                                       <br/>
      <Box id="form-box" className="audit_form_box">
            <div id="audit-form-div" className="audit_form_div">
            <Stack id="stack-row-first" spacing={spacingSize} direction="row" className="stack_textField">
                 <Autocomplete required
                                   onChange={handleApplicationChange}
                                   value={application}
                                   id="combo-box-demo"
                                   options={appData.map((option) => option.applicationName)}
                                   className="auto-complete"
                                   renderInput={(params) => <TextField {...params}
                                            label="Application"
                                            required
                                            placeholder="Search Application"
                                                InputProps={{ ...params.InputProps,
                                                startAdornment: ( <InputAdornment position="start"> <AppsIcon />
                                                </InputAdornment> ),
                                                disableUnderline: true
                                                 }} />}

                                 />
                 <TextField id="email-Id" className="auto-complete"
                                type="text"
                                inputRef={emailId}
                                variant='outlined'
                                color='secondary'
                                label="Email Id" />

            </Stack>

            <Stack id="stack-row-second" spacing={spacingSize} direction="row" className="stack_textField">

            <Autocomplete className="auto-complete"
                          onChange={(event, actionValue) => setAction(actionValue)}
                          id="action"
                          value={action}
                          options={actionData.map((option) => option.value)}
                          renderInput={(params) => <TextField {...params}
                          label="Action"
                          placeholder="Search Action"
                          InputProps={{ ...params.InputProps,
                                     startAdornment: ( <InputAdornment position="start">   <HistoryIcon />
                                                            </InputAdornment> ),
                                     disableUnderline: true
                                      }} />}

                          />
            <TextField id="report-format" select required
                              className="audit_textField"
                                                                           label="Report Format"
                                                                           value={reportFormat}
                                                                           variant="outlined"
                                                                           color='secondary'
                                                                           inputRef={formatRef}
                                                                           onChange={handleReportFormatChange}
                              SelectProps={{
                                          native: true,
                                          }}
                              InputProps={{
                                          startAdornment: (
                                                             <InputAdornment position="start">
                                                                  <SaveIcon />
                                                            </InputAdornment>
                                                           ),
                                          }}
                                           >
                                          {reportFormatData.map((reportFormatList) => (
                                          <option key={reportFormatList.value} value={reportFormatList.value}>
                                                {reportFormatList.value}
                                          </option>
                                           ))}
                        </TextField>
            </Stack>
                 <LocalizationProvider id="local-provider" dateAdapter={AdapterDayjs}>
                     <DemoContainer id="demo-container" components={['DateTimePicker','TimeField']}>
                        <Stack id="stack-row-third" spacing={spacingSizeDate} direction="row" className="stack_textField" style={{'margin-top': '1%'}}>
                            <DatePicker id="from-date" className="date-picker"  format="DD/MM/YYYY"
                                         value={dayjs(fromDate)} label={'From Date'}
                                         views={['year', 'month', 'day']} disableFuture required
                                         onChange={(fromDateValue) => {
                                                 setFromDate(fromDateValue);
                                                }}
                                        slotProps={{
                                                     textField: {
                                                                  helperText: 'DD/MM/YYYY',
                                                                  id: "from_date"
                                                                  
                                                    },
                                       }}
                            />
                            <TimePicker id="from-time" className="time-picker"
                                        ampm={false}
                                        openTo="hours"
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        label="GMT"
                                        value={dayjs(fromTime)}
                                        onChange={(fromTimeValue) => {
                                             setFromTime(fromTimeValue);
                                             }}
                                        slotProps={{
                                                textField: {
                                                            helperText: 'HH:MM',
                                                            id: "from_time"
                                                            },
                                               }}
                            />

                        </Stack>
                        <Stack id="stack-row-fourth"  spacing={spacingSizeDate} direction="row" className="stack_textField">
                            <DatePicker id="to-date" className="date-picker"
                                         format="DD/MM/YYYY"
                                         value={dayjs(toDate)}
                                         label={'To Date'}
                                         openTo="day"
                                         required
                                         onChange={(toDateValue) => {
                                                    setToDate(toDateValue);
                                                  }}
                                         slotProps={{
                                                                textField: {
                                                                             helperText: 'DD/MM/YYYY',
                                                                              id: "to_date"
                                                                   },
                                                                }}  />

                              <TimePicker required id="to-time"
                                          className="time-picker"
                                          ampm={false}
                                          openTo="hours"
                                          views={['hours', 'minutes']}
                                          inputFormat="HH:mm"
                                          mask="__:__"
                                          label="GMT"
                                          value={dayjs(toTime)}
                                          onChange={(toTimeValue) => {
                                                       setToTime(toTimeValue);
                                                   }}
                                       slotProps={{
                                             textField: {
                                                          helperText: 'HH:MM',
                                                           id: "to_time"
                                                         },
                                             }}
                              />


                        </Stack>
                     </DemoContainer>
                 </LocalizationProvider>
            <Stack id="stack-row-fifth"  spacing={2} direction="row" className="audit_stack_button">
             <Box id="box-btn" sx={{ m: 1, position: 'relative' }}>
              <Tooltip id="btn-tooltip" title={configData.reportingMessage}>
                <Button id="audit-btn" style={{background:'red'}}
                        disabled={loading}
                        variant="contained"
                        onClick={generateAuditReport}>
                        Generate Report
                </Button>
              </Tooltip>
                                                               {loading && (
                                                                        <CircularProgress
                                                                          size={40}
                                                                          sx={{
                                                                            color: 'grey',
                                                                            position: 'absolute',
                                                                            top: '10%',
                                                                            left: '50%',
                                                                            marginTop: '-14px',
                                                                            marginLeft: '-12px',
                                                                          }}
                                                                        />
                                                                      )}
                                                                     </Box>
                <Button id="audit-cancel" style={{background:'grey'}} onClick={handleReset}
                         variant="contained">
                        Reset
                </Button>
                 <Snackbar id="snack-bar" open={alertOpen} autoHideDuration={1000} onClose={handleAlertClose}>
                             <Alert id="mui-alert" onClose={handleAlertClose} severity={alertSeverity} sx={{width: '100%'}}>{alertMessage}</Alert>
                 </Snackbar>
            </Stack>
      </div>
  </Box>
  </div>
  );
}
