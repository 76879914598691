import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {  CardActionArea, CardActions } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import auditLogImage from "../assets/images/auditLog.png";
import SessionImage from "../assets/images/sessions.png";
import ReportingImage from "../assets/images/img_6.png";
import ApplicationImage from "../assets/images/applications.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import { useNavigate } from 'react-router-dom';


const images = [
  {
    label: 'Applications',
    imgPath:
      ApplicationImage,
    path: '/applications',
  },
  {
    label: 'Reporting',
    imgPath:
      ReportingImage,
    path: '/reporting',
  },
  {
    label: 'Audit logs',
    imgPath: auditLogImage,
    path: '/applications',
  },
  {
    label: 'Sessions',
    imgPath:
      SessionImage,
    path: '/sessions',
  },
];

function Slider() {
    const navigate=useNavigate();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [navigatePath,setNavigatePath]=React.useState(0);
  const maxSteps = images.length;

  const handleApi =()=>{


         navigate(activeStep.path);

};
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (

    <Box className="dashboard_box" onClick={handleApi}>


      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval= '9000'
        enableMouseEvents
      >

        {images.map((step, index) => (

          <div key={step.label}>

            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                sx={{
                  height: 400,
                  display: 'block',

                  overflow: 'hidden',
                  width: '100%',
                  backgroundImage: `url(${step.imgPath})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'left',
                  cursor: 'pointer',
                  position: 'relative',

                }}


            >
            <Typography
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: 'rgba(0,0,0,0.3)',
                    color: 'white',
                    padding: '10px',
                    textAlign: 'center',

                    }}
            >{step.label}</Typography>

              </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />

    </Box>



  );
}

export default Slider;