import * as React from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";
import "../assets/css/Common.css";
import "../assets/css/Header.css";
import { configData } from "../config/config-dev";
import axios from "axios";
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import ChangePassword from "../components/ChangePassword";
import EditDetails from "../components/EditDetails";
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
//import SessionTimeout from "../components/SessionTimeout";


function MainHeader() {

const url = configData.gateWayUrl + configData.myaccountPageContent;
const environment = configData.environment;
const AAA = configData.AAA;
const name = configData.name;
const logoutUrl = configData.gateWayUrl + configData.logoutUrl;
const autheticatorUrl=configData.gateWayUrl + configData.accountauthenticatorList;
const userName =  sessionStorage.getItem('UserName');
const isVodafoneUser =  sessionStorage.getItem('isVodafoneUser');
const sessionId = sessionStorage.getItem('SessionId');
const navigate = useNavigate();
const [open, setOpen] = React.useState(false);
const [schangepassword, setChangePassword] = React.useState(false);
const [editDetails, setEditDetails] = React.useState(false);
const anchorRef = React.useRef(null);

const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const menuChangePasswordSelect = () => {
      setOpen(false);
      setChangePassword(true);
    };
    const menuEditDetailsSelect = () => {
          setOpen(false);
          setEditDetails(true)
        };

   const handleLogoutApi = () => {

                        axios.get(logoutUrl, {
                                   sessionId: sessionId,
                                   })
                                 .then(function (response) {
                                   navigate("/")
                                 })
                                 .catch(function (error) {
                                  navigate("/")
                                 });

     };

    return (
      <>
       <div style={{background:'#FAFAFA'}}>
       	<ChangePassword show={schangepassword} close={() => setChangePassword(false)} />
       	<EditDetails show={editDetails} close={() => setEditDetails(false)} />

         <table className="header_table">
                		<tbody><tr>
                		<div>
                			<td width="40"><img className="header_image" alt="Alt content" src={require('./../assets/images/vodafone.png')} /></td></div>

                                         		    <td width="60"><h2 className="h2_label">{AAA}</h2></td>
                                         		    <td width="40"><h4 className="h4_label">{name}</h4></td>
                		<span className="mainHeaderuserContainer">
                     <td class="cell"><h3>
                     <span style={{color: '#272c36'}}> Environment : {environment}</span>
                                   <Button  onClick={handleClickOpen}>
                                       <Avatar style={{background: 'red'}}>{userName.match(/(\b\S)?/g).join("").toUpperCase()}</Avatar>
                                   </Button>
                                    <Dialog
                                           open={open}
                                           onClose={handleClose}
                                           aria-describedby="alert-dialog-slide-description"
                                         >
                                           <DialogContent>
                                             <DialogContentText id="alert-dialog-slide-description">
                                             <MenuList
                                                                                      autoFocusItem={open}
                                                                                      id="composition-menu"
                                                                                      aria-labelledby="composition-button"
                                                                                    >
                                                                                    <span className="user-span"> {userName}  </span>
                                                                                    <Divider/>
                                                                                      {isVodafoneUser && (<MenuItem className="menu-item" onClick={menuChangePasswordSelect}>Change Password</MenuItem>)}
                                                                                      {isVodafoneUser && (<MenuItem className="menu-item" onClick={menuEditDetailsSelect}>Edit Details</MenuItem>)}
                                                                                      {!isVodafoneUser && (<MenuItem className="menu-item" onClick={menuEditDetailsSelect}>Show Details</MenuItem>)}
                                                                                    <Divider/>
                                                                                     <span className="logout-span" onClick={handleLogoutApi}><LogoutIcon/> Logout</span>
                                                                                    </MenuList>
                                             </DialogContentText>
                                           </DialogContent>
                                         </Dialog>

                		</h3></td>

                		</span>
                		</tr>
                	</tbody></table>


       	 <div id="navigation"></div>

              	</div>
      </>
       );
   }
export default MainHeader;