import React from "react";

import Slider from "../components/Slider";
import WelcomePage from "../components/WelcomePage";
import Contents from "../components/Contents";
import axios from "axios";
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import CdbSideBar from "../components/CdbSideBar";
import "../assets/css/Content.css";
import { configData } from "../config/config-dev";
import {Table,TableBody,TableCell,TableRow} from '@mui/material';


const restrictedApi = configData.gateWayUrl + configData.restricted;

 export async function dataLoader()  {
    const res = await axios.post(restrictedApi,{
        withCredentials:true,
    }).
    then(function (response) {
     if(!response.data.isSuccess){
        window.location="/";
     }}).
    catch(function (error) {
      window.location="/";
    });
    return null;
  };

const Dashboard = () => {
const sidebarTd={ paddingLeft: '0.0%',paddingTop: '0.0%',width: '10%',verticalAlign: 'top', textAlign: 'left'};
 const contentsTd={ paddingLeft: '0.0%',paddingTop: '0.0%',verticalAlign: 'top', textAlign: 'left'};
  return (
  <div>
    <Header/>
    <div>
           <Table>
           <TableRow>
               <TableCell style={sidebarTd}>
        <CdbSideBar/>
        </TableCell>

        <TableCell style={contentsTd}>
        <Contents/>
        </TableCell>

        </TableRow>
        </Table>
        </div>
    <Footer/>
    </div>
  );
};

export default Dashboard;
