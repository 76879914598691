import React from "react";
import Contents from "../components/AuditLogComponent";
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import CdbSideBar from "../components/CdbSideBar";
import "../assets/css/Content.css";
import {Table,TableCell,TableRow} from '@mui/material';

const AuditLog = () => {

 const sidebarTd={ paddingLeft: '0.0%',paddingTop: '0.0%',width: '10%',verticalAlign: 'top', textAlign: 'left'};
 const contentsTd={ paddingLeft: '0.0%',paddingTop: '0.0%',verticalAlign: 'top', textAlign: 'left'};

    return (
     <div>
      <Header/>
      <div>
             <Table>
             <TableRow>
                 <TableCell style={sidebarTd}>
          <CdbSideBar/>
          </TableCell>
          <TableCell  style={contentsTd}>
          <Contents/>
          </TableCell>
          </TableRow>
          </Table>
          </div>
      <Footer/>
      </div>
  );
};

export default AuditLog;
