import React from 'react';
import './App.css';
import { BrowserRouter,Routes,Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard, { dataLoader } from './pages/Dashboard';
import Applications from "./pages/Applications";
import UserDetails from "./pages/UserDetails";
import UserDataTable from "./components/UserDataTable";
import ApplicationView from "./pages/ApplicationView";
import Sessions from "./pages/Sessions";
import EditUserDetails from "./pages/EditUserDetails";
import BulkUpload from "./pages/BulkOperationPage";
import AuditReport from "./pages/AuditReportPage";
import UserReport from "./pages/UserReportPage";
import Reporting from "./pages/Reporting";
import MySlider from "./components/MySlider"
import AuditLogs from "./pages/AuditLog";
import Administrator from "./pages/AdministratorPage";
import AddUser from "./pages/AddUserPage";
import UserDetailsApplication from "./pages/UserDetailsApplication";
import RedirectPage from "./components/RedirectLoginPage";
import ErrorPage from "./pages/ErrorPage";
import {configData} from "./config/config-dev";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
        <Route index path="/applications" element={<Applications/>} loader={dataLoader} />
        <Route index path="/" element={<Login/>}  />
        <Route index path={configData.redirectUrlPattern} element={<RedirectPage/>}  />
        <Route index path="/unauthorized" element={<ErrorPage/>}  />
        <Route path="/dashboard" element={<Dashboard/>}  loader={dataLoader}/>
        <Route path="/userdetails" element={<UserDetails/>} loader={dataLoader} />
        <Route path="/viewApplication" element={<ApplicationView/>} loader={dataLoader} />
        <Route path="/sessions" element={<Sessions/>} loader={dataLoader} />
        <Route path="/editUserDetails" element={<EditUserDetails/>} loader={dataLoader} />
        <Route path="/upload" element={<BulkUpload/>} loader={dataLoader} />
        <Route path="/auditReport" element={<AuditReport/>} loader={dataLoader} />
        <Route path="/userReport" element={<UserReport/>} loader={dataLoader} />
        <Route path="/reporting" element={<Reporting/>} loader={dataLoader} />
        <Route path="/slider" element={<MySlider/>}  loader={dataLoader} />
        <Route path="/auditLog" element={<AuditLogs/>} loader={dataLoader} />
        <Route path="/administrator" element={<Administrator/>} loader={dataLoader} />
        <Route path="/adduser" element={<AddUser/>} loader={dataLoader} />
        <Route path="/getUserApplication" element={<UserDetailsApplication/>} loader={dataLoader} />
   </Route>
  )
);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
