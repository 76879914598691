  import  React from 'react';
  import "../assets/css/Footer.css"
  import Divider from '@material-ui/core/Divider';

  const FooterNav = () => {
    return (
    <div class="div_footer">
    <p className="paragraph-title">Privacy     PolicyTerms   and   Conditions &copy; 2024 Vodafone group</p>
     <Divider />
   </div>

    );
  };
  export default FooterNav;

