import * as React from "react";
import "../assets/css/Header.css";
import { useNavigate } from "react-router-dom";
import { configData } from "../config/config-dev";
import {Table,TableBody,TableCell,TableRow} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useLocation } from "react-router-dom";

function Header() {

   const LogoId={ paddingLeft: '0.0%',paddingTop: '0.0%' ,width: '85%' ,paddingBottom: '0.0%' };
   const envId={ paddingLeft: '0.0%', paddingTop: '0.0%' ,paddingBottom: '0.0%'};
   const environment = configData.environment;
   const AAA = configData.AAA;
   const name = configData.name;
   const userName =  sessionStorage.getItem('UserName');
   const location = useLocation();
   const appData = location.state;
   const navigate = useNavigate();

   const handleLogoutApi = () => {
       event.preventDefault();
        navigate("/");

     };

    return (
    <div style={{background:'#FAFAFA'}}>
    		{(!appData || Object.keys(appData).length === 0) ? (
    		<Table>
                                       <TableRow >
                                                       <TableCell  style={LogoId}>
                                                            <td width="40" style={{'vertical-align': 'middle'}}><img className="header_image"  alt="Alt content" src={require('./../assets/images/vodafone.png')}  /></td>
                                                            <td width="20"><h2 className="h2_label">{AAA}</h2></td>
                                                            <td width="10"><h4 className="h4_label">{name}</h4></td>
                                                       </TableCell>
                                                       <TableCell style={envId}>
                                                       <td>
                                                              <h3 className="header_h3" >Environment : {environment}</h3> </td>
                                                       </TableCell>
                                       </TableRow>
                               </Table>
                  ) : (
                 <Table>
                                            <TableRow >
                                                            <TableCell  style={LogoId}>
                                                                 <td width="40" style={{'vertical-align': 'middle'}}><img className="header_image"  alt="Alt content" src={require('./../assets/images/vodafone.png')}  /></td>
                                                                  <td width="40"><h4 className="h4_label">{appData.appName}</h4></td>
                                                            </TableCell>
                                                            <TableCell style={envId}>
                                                            <td>
                                                                   <h3 className="header_h3" >Environment : {environment}</h3> </td>
                                                            </TableCell>
                                            </TableRow>
                                    </Table>
                  )}
      <div id="navigation"></div>
    	</div>
    );
}

export default Header;
