import * as React from 'react';
import {Container,Grid} from '@mui/material';
import Cards from '../components/Cards'
import Slider from '../components/Slider'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AppsIcon from '@mui/icons-material/Apps'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { useNavigate } from 'react-router-dom';
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import CdbSideBar from "../components/CdbSideBar";
import "../assets/css/Content.css";
import {Table,TableBody,TableCell,TableRow} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import {  CardActionArea, CardActions ,Typography,IconButton} from '@mui/material';

const Administrator=()=>{
    const appName = sessionStorage.getItem('ApplicationName');
    const sessionId = sessionStorage.getItem('SessionId');
    const sidebarTd={ paddingLeft: '0.0%',paddingTop: '0.0%',width: '10%',verticalAlign: 'top', textAlign: 'left'};
     const contentsTd={ paddingLeft: '0.0%',paddingTop: '0.0%',verticalAlign: 'top', textAlign: 'left'};



    return(
        <div>
            <Header/>
            <div>
                   <Table>
                   <TableRow>
                       <TableCell style={sidebarTd}>
                <CdbSideBar/>
                </TableCell>

                <TableCell style={contentsTd}>
                <Container sx={{marginTop: "3%", marginLeft: "-3%"}}>
                    <Typography sx={{color: "Black",paddingLeft: "3%"}} gutterBottom variant="h6" component="div">
                        <AdminPanelSettingsIcon/> Administrator for {appName}
                     </Typography>
                            <Grid container spacing={8}>
                              <Grid  item xs={12} md={5}>
                                 <Grid Container sx={{display: "flex",marginLeft: "10%"}} spacing={1}  >
                                    <Grid Container sx={{display: "column"}} spacing={1}  >
                                     <Grid item xs={12} md={12}>
                                     <Cards title="Change Application"  icon={<AppRegistrationIcon/> } path="/applications" iconColor="white" cardColor="#057ea3"/>
                                     </Grid>
                                     <Grid item xs={12} md={12}>
                                     <Cards title=" Add Users" icon={<PersonAddAlt1Icon/>} path="/adduser" iconColor="white" cardColor="#8BC34A"/>
                                     </Grid>
                                 </Grid>
                                 <Grid Container sx={{display: "column"}} spacing={1}  >
                                     <Grid item xs={12} md={12}>
                                     <Cards title="Manage Users" icon={<ManageAccountsIcon  />} path="/auditLog" iconColor="white" cardColor="#FF9800"/>
                                     </Grid>
                                     <Grid item xs={12} md={12}>
                                     <Cards title="Remove Users" icon={<PersonRemoveIcon/>} path="/reporting" iconColor="white" cardColor="red"/>
                                     </Grid>
                                 </Grid>
                                 </Grid>
                              </Grid>
                            </Grid>
                         </Container>
                </TableCell>

                </TableRow>
                </Table>
                </div>
            <Footer/>
            </div>

        );
    }
export default Administrator;