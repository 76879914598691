import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { configData } from "../config/config-dev";
import axios from "axios";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import validator from "validator";
import { useNavigate } from "react-router-dom";


const ChangePassword = (props) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [sucessModal, setSucessModal] = React.useState(false);
  const [policy, setPolicy] = React.useState("");
  const [responsePolicy, setResponsePolicy] = React.useState("");
  const [passwordPol, setPasswordPol] = React.useState("");
  const [openPol, setOpenPol] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const url = configData.gateWayUrl + configData.getPasswordPolicy;
  const changepasswordUrl = configData.gateWayUrl + configData.changePassword;
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getPasswordPolicies = () => {
    axios
      .post(url)
      .then(function (response) {
        setResponsePolicy(response.data.passwordPolicies);
        localStorage.setItem("passwordPolicy" , response.data.passwordPolicies);
        localStorage.setItem("passwordPolicyAll" , response.data);
        setPasswordPol(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  window.addEventListener("beforeunload", (event) => {
  let passwordLoaded = localStorage.getItem("isPasswordLoaded");
  let passwordPolicyVal = localStorage.getItem("passwordPolicyAll");
  if(passwordLoaded === null || passwordPolicyVal === null || !passwordLoaded ){
    localStorage.setItem("isPasswordLoaded" , true);
    getPasswordPolicies();
    }
    else{
    setResponsePolicy(localStorage.getItem("passwordPolicy" ));
    setPasswordPol(localStorage.getItem("passwordPolicyAll" ));
    }
  });

const handleClose = (event, reason) => {
      if (reason && reason === "backdropClick")
          return;
      props.close();
  }
  const handleModalClose = () => {
  if(sucessModal){
       setSucessModal(false);
       setOpen(false);
       props.close();
       navigate("/dashboard");
       }
     else{
     setOpen(false);
     }
    }

  return (
    <React.Fragment>
      <Dialog
        open={props.show}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const oldpassword = formJson.oldpassword;
            const newpassword = formJson.newpassword;
            const confirmpassword = formJson.confirmpassword;
            const isStrongPassword = false;
            const sessionids = sessionStorage.getItem("SessionId");
            if (newpassword != confirmpassword) {
              setErrorMessage(
                " New Passwords and Confirm Passwords are not same"
              );
              setOpen(true);
            }
            if (newpassword != confirmpassword) {
              setErrorMessage(" New Passwords and Confirm Passwords are different");
              setOpen(true);
            } else if (
              validator.isStrongPassword(newpassword, {
                minLength: passwordPol.minpolicyLength,
                minLowercase: passwordPol.minLowerLetters,
                minUppercase: passwordPol.minCapitalLetters,
                minNumbers: passwordPol.minDigits,
                minSymbols: passwordPol.minSymbols,
                maxLength: passwordPol.maxpolicyLength,
              })
            ) {
              axios
                .post(changepasswordUrl, {
                  oldPassword: oldpassword,
                  newPassword: newpassword,
                  sessionId: sessionids,
                })
                .then(function (response) {
                  if (response.data.isSuccess) {
                  setSucessModal(true);
                  setErrorMessage("Password changed successfully");
                  setOpen(true);
                  } else if (
                    response.data.errorMessage !== null &&
                    response.data.errorMessage !== ""
                  ) {
                    setErrorMessage(response.data.errorMessage);
                    setOpen(true);
                  } else {
                    setErrorMessage("Please retry with Correct credentials");
                    setOpen(true);
                  }
                });
            } else {
              setErrorMessage(
                " New Passwords is not as per policy, please check password policy"
              );
              setOpen(true);
            }
          },
        }}
      >
        <DialogTitle>Change Password</DialogTitle>

        <DialogContent>
          <Card
            sx={{
              border: "1px solid rgba(211,211,211,0.6)",
            }}
          >
            <CardHeader
              subheader="Password Policies"
              action={
                <IconButton
                  onClick={() => setOpenPol(!openPol)}
                  aria-label="expand"
                  size="small"
                >
                  {openPol ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              }
            ></CardHeader>
            <div
              style={{
                backgroundColor: "rgba(211,211,211,0.4)",
              }}
            >
              <Collapse in={openPol} timeout="auto" unmountOnExit>
                <CardContent>
                  <Container
                    sx={{
                      height: 100,
                      lineHeight: 2,
                    }}
                  >
                    {responsePolicy}
                  </Container>
                </CardContent>
              </Collapse>
            </div>
          </Card>

          <TextField
            autoFocus
            required
            margin="dense"
            id="oldpassword"
            name="oldpassword"
            label="Old Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="newpassword"
            name="newpassword"
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="confirmpassword"
            name="confirmpassword"
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.close}>Cancel</Button>
          <Button variant="contained" type="submit">Submit</Button>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={handleModalClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                maxWidth: 500,
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
              }}
            >
              <ModalClose variant="soft" sx={{ m: 1 }} />
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
                Message
              </Typography>
              <Typography id="modal-desc" textColor="text.tertiary">
                <code>{errorMessage}</code>
              </Typography>
            </Sheet>
          </Modal>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ChangePassword;
