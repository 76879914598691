import * as React from 'react';
import {useState,useEffect} from "react";
import "../assets/css/Content.css";
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Person from '@mui/icons-material/Person';
import People from '@mui/icons-material/People';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Apartment from '@mui/icons-material/Apartment';
import Box from '@mui/material/Box';
import {TextField, Button, Stack} from '@mui/material';
import InputAdornment from "@mui/material/InputAdornment";
import AppsIcon from '@mui/icons-material/Apps';
import { DropzoneArea } from 'material-ui-dropzone';
import { useLocation } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useNavigate } from 'react-router-dom';
import { configData } from "../config/config-dev";
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import {Panel,FlexboxGrid} from "rsuite";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';



export default function BulkOperation() {

const errorMessage="Unable to process the bulk operation";

const Divider = () => {
    return <hr style={{ borderTop: "1px solid grey" }}></hr>;
  };

const [alertSeverity,setAlertSeverity]=useState('success');
const [loading, setLoading] = React.useState(false);

const [success, setSuccess] = React.useState("");
const [alertOpen,setAlertOpen]=useState(false);

const [alertMessage,setAlertMessage]=useState('');
const [open, setOpen] = React.useState(false);
const [message, setMessage] = React.useState("")
const [tabValue, setTabValue] = React.useState(0);
const sessionId = sessionStorage.getItem('SessionId');
const [appData,setAppData]=useState([]);
const applicationUrl = configData.gateWayUrl + configData.applicationForAdmin;
const backendError=configData.backendNotAvailable;
const bulkOpUrl = configData.bulkOpUrl + configData.bulkAssignRole;
const bulkOption = JSON.parse(configData.bulkOption);

const spacingSizeFour=4;
const badRequest=400;
const successResp=200;
const internalServerError=500;

const [application, setApplication] = React.useState("");
const [action, setAction] = useState('');
const [selectedFile, setSelectedFile] = useState(null);


  const handleReset = (e) => {
               setApplication("");
               setAction("");
               setSelectedFile(null);

      };


const handleAlertClose=(event,reason)=>{
        if(reason=='clickaway'){
            return;
            }
        setAlertOpen(false);
};

  const handleModalClose = () => {
        setMessage("");
        setOpen(false);
    };

  const handleAppChange = (event, value) => {
        setApplication(value);
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };


 const handleFileChange =  (files) => {
     files.forEach((file) => {
       setSelectedFile(file);
     })
     }

 useEffect(()=>{

 	const fetchData=async ()=>{

 		await axios.post(applicationUrl,{
 			  sessionId: sessionId
 		}).then(response=>{
 		if(response.data) {
 		    setAppData(response.data);
 		}
 		})
 		.catch(error=>{
 		console.error('Error while fetching application',error);
 	  });
 	  };
     fetchData();
    },[]);

 const handleBulkOperation = () => {


      if (application === "" || application== null ) {
                   setOpen(true);
                   setMessage("Select Application");
      }
      else if (action === "" || action== null ) {
                         setOpen(true);
                         setMessage("Select Action");
       }
      else if (selectedFile === "" || selectedFile== null ) {
                         setOpen(true);
                         setMessage("select csv file");
      }
      else {
       const formData = new FormData();
       formData.append('file', selectedFile);
       formData.append('sessionId', sessionId);
       formData.append("appName",application);
       formData.append("opsType",action);

       axios.post(bulkOpUrl, formData)
             .then(function (response) {
                 if(response.data.statusCode==successResp){
                       setAlertMessage(response.data.message);
                       setAlertSeverity('success');
                       setAlertOpen(true);
                 } else if(response.data.statusCode==badRequest) {
                       setAlertMessage(response.data.message);
                       setAlertSeverity('error');
                       setAlertOpen(true);
                 }
                 else if(response.data.statusCode==internalServerError){
                        setAlertMessage(backendError);
                        setAlertSeverity('error');
                        setAlertOpen(true);
                 }
                 else {
                   setAlertMessage(errorMessage);
                   setAlertSeverity('error');
                   setAlertOpen(true);
                 }
             })
             .catch(function (error) {
                   console.log(error);
                   setSuccess(true);
                   setLoading(false);
                   setOpen(true);
                   setMessage(errorMessage);
             });
      }
  };

  return (
    <div>
      <Modal
               open={open}
               onClose={handleModalClose}
               id="modal-head"
               sx={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 border: 1,
               }}
             >
               <Sheet
                 variant="outlined"
                 id="modal-sheet-head"
                 sx={{
                   maxWidth: 300,
                   minWidth: 200,
                   borderRadius: "lg",
                   p: 3,
                   boxShadow: "lg",
                   border: 1.5,
                 }}
               >
                 <ModalClose
                   variant="solid"
                   id="modal-close"
                   sx={{
                     m: 1,
                     border: 1,
                     size: "lg",
                     bgcolor: "red",
                     justifyContent: "center",
                     margin: -.5,
                   }}
                 />
                 <Panel
                   id="modal-Panel"
                   shaded
                   bordered
                   style={{
                     marginTop: "12%",
                     backgroundColor: "white",
                   }}
                 >
                   <div id="modal-title" class="modal-title">
                     <span id="error-message">{configData.errorTittle}</span>
                   </div>
                   <Divider />
                   <div id="modal-desc" class="modal-desc">
                     <span id="message">{message}</span>
                   </div>
                 </Panel>
                 <FlexboxGrid id="modal-flexboxgrid"justify="center" style={{ margin: 15 }}>
                   <Button
                     variant="contained"
                     color="error"
                     id="closeModal-btn"
                     onClick={handleModalClose}
                     fullWidth="true">
                     {configData.closeButton}
                   </Button>
                 </FlexboxGrid>
               </Sheet>
             </Modal>


             <Paper>
                     <Tabs
                                    variant="plain"
                                    color="neutral">
                                   <Tab  style={{textTransform: 'none',fontSize: '14px' ,'font-weight': 'bold','align-items': 'initial',minWidth: 90}} label="Bulk Operation" />
                     </Tabs>
             </Paper>
             <br/>
       <Box className="bulk_form_box">
          <Stack spacing={2} direction="row" style={{marginLeft:'7%'}}>
                      <Autocomplete
                                    onChange={handleAppChange}
                                     value={application}
                                    id="combo-box-demo"
                                    options={appData.map((option) => option.applicationName)}
                                    className="auto-complete"
                                    renderInput={(params) => <TextField {...params}
                                                              label="Application"
                                                              required
                                    placeholder="Search Application"
                                    InputProps={{ ...params.InputProps,
                                                   startAdornment: ( <InputAdornment position="start"> <AppsIcon />
                                                                   </InputAdornment> ),
                                                   disableUnderline: true
                                                }} />}

                      />
                      </Stack>
                       <Stack spacing={10}>


          </Stack>

        <Stack spacing={3}  style={{marginTop:'2%'}} direction="row">
            <FormLabel>Action</FormLabel>
       <RadioGroup  name="Action" defaultValue="Individual"  value={action} onChange={handleActionChange}>
             <List
               sx={{
                 minWidth: 250,
                 minHeight: 150,
                 '--List-gap': '0.7rem',
                 '--ListItem-paddingY': '10px',
                 '--ListItem-radius': '8px',
                 '--ListItemDecorator-size': '10px',
               }}
             >
               {bulkOption.map((item, index) => (
                 <ListItem variant="outlined" key={item}>
                   <ListItemDecorator>
                     {[<People />,<Person />][index]}
                   </ListItemDecorator>
                   <Radio
                     overlay
                     value={item.value}
                     label={item.label}
                     sx={{ flexGrow: 2, flexDirection: 'row-reverse' }}
                     slotProps={{
                       action: ({ checked }) => ({
                         sx: (theme) => ({
                           ...(checked && {
                             inset: -1,
                             border: '1px solid',
                             borderColor: theme.vars.palette.primary[100],
                           }),
                         }),
                       }),
                     }}
                   />
                 </ListItem>
               ))}
             </List>
           </RadioGroup>
                     <DropzoneArea  acceptedFiles={[".csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                         filesLimit="1"
                                         dropzoneText={"Drag and drop a csv file or click"}
                                         showFileNames="true"
                                         onChange={handleFileChange} />
                   </Stack>
        <Stack spacing={2} direction="row" style={{marginLeft:'8%'}}>
             <Button disabled={loading} style={{background: 'red'}} variant="contained"  onClick={handleBulkOperation}> Submit</Button>
             <Button style={{background: 'grey'}} variant="contained" onClick={handleReset}> Reset </Button>

              <Snackbar id="snack-bar" open={alertOpen} autoHideDuration={70000} onClose={handleAlertClose}>
                                       <Alert id="mui-alert" onClose={handleAlertClose} severity={alertSeverity} sx={{width: '100%'}}>{alertMessage}</Alert>
                               </Snackbar>
                                </Stack>
        {loading && (
                                                                               <CircularProgress
                                                                                 size={60}
                                                                                 sx={{
                                                                                   color: 'grey',
                                                                                   position: 'absolute',
                                                                                   top: '10%',
                                                                                   left: '50%',
                                                                                   marginTop: '-14px',
                                                                                   marginLeft: '-12px',
                                                                                 }}
                                                                               />
                                                                             )}




      </Box>
    </div>
  );
}
