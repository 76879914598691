import React, { useState } from "react";
import { TextField, Button, Container, Stack} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../assets/css/Content.css";
import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';
import { Divider } from "primereact/divider";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { configData } from "../config/config-dev";

const ApplicationView = () => {

  const location = useLocation();
  const applicationDetails = location.state;
  const applicationData = location.state.applicationProperties;


  return (
    <div >
    <Box
                       sx={{
                         flexWrap: 'wrap',
                         p: 1,
                         m: 1,
                         bgcolor: 'background.paper',
                         borderRadius: 1,
                         border: '1px solid',
                       }}
                     >
      <Divider align="left" >
        <div className="inline-flex align-items-center">
          <strong>Edit User</strong>
        </div>
      </Divider>
      <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
        <TextField
          readOnly
          type="text"
          variant="outlined"
          label="User Id"
          value={applicationDetails.accountId}
          inputProps={{ readOnly: true }}
        />
        <TextField
          type="text"
          variant="outlined"
          label="Vfuid"
          value={applicationDetails.vfuid}
          inputProps={{ readOnly: true }}
        />
        <TextField
          readOnly
          type="text"
          variant="outlined"
          label="Email"
          value={applicationDetails.email}
          inputProps={{ readOnly: true }}
        />

        <TextField
          type="text"
          variant="outlined"
          label="First Name"
          value={applicationDetails.firstname}
          inputProps={{ readOnly: true }}
        />
        </Stack>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
        <TextField
          readOnly
          type="text"
          variant="outlined"
          label="Surname"
          value={applicationDetails.surname}
          inputProps={{ readOnly: true }}
        />
        <TextField
          type="text"
          variant="outlined"
          label="Phone"
          value={applicationDetails.phone}
          inputProps={{ readOnly: true }}
        />
        <TextField
          readOnly
          type="text"
          variant="outlined"
          label="Comapny"
          value={applicationDetails.company}
          inputProps={{ readOnly: true }}
        />
        <TextField
          type="text"
          variant="outlined"
          label="Department"
          value={applicationDetails.department}
          inputProps={{ readOnly: true }}
        />
        </Stack>
         <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
        <TextField
          readOnly
          type="text"
          variant="outlined"
          label="Active"
          value={applicationDetails.activeEntry}
          inputProps={{ readOnly: true }}
        />
        <TextField
          type="text"
          variant="outlined"
          label="Internal"
          value={applicationDetails.activeEntry}
          inputProps={{ readOnly: true }}
           />
      </Stack>
      <div class="header-title">
      <span >Account Properties</span>
      </div>

          <div >
                {applicationData.map(board => (
                <div>
                <Divider align="left" >
                                              <div className="inline-flex align-items-center">
                                                <strong>{board.applicationName}</strong>
                                              </div>
                                            </Divider>

                  <div key={board.applicationName}>
                  <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                    {board.properties.map(thread => (
                    <div>
                      <TextField
                      readOnly
                      type="text"
                      variant="outlined"
                      label={thread.name}
                      value={thread.value}
                              />
                      </div>
                    ))}
                    </Stack>
                  </div>
                   </div>
                ))}
                </div>
      </Box>
    </div>
  );
};

export default ApplicationView;
