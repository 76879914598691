export var configData = {

  gateWayUrl: process.env.REACT_APP_GATEWAY_URL,
  bulkOpUrl: process.env.REACT_APP_BULK_OPS_URL,
  authenticateUrl: "authenticate",
  applicationForAdmin: "applications/admin",
  application: "application",
  sessions: "sessions",
  accountListUserDetails: "accountListUserDetails",
  logoutUrl: "logout",
  inValidateUrl: "inValidate/session",
  myaccountPageContent: "myAccountPageContent",
  accountauthenticatorList: "accountauthenticatorList",
  getPasswordPolicy: "passwordPolicy",
  changePassword: "changePassword",
  getUserDetails: "getUserDetails",
  getUser: "getUser",
  getUserApplication: "getUserApplication",
  globalEditUserParams: "globalEditUserParams",
  UpdateUserDetails: "UpdateUserDetails",
  getRole: "getRoles",
  globalEditUserParams: "globalEditUserParams",
  UpdateUserDetails: "UpdateUserDetails",
  auditReportApi: "auditReport",
  userReportApi: "userReport",
  auditLogDetails: "auditDetails",
  allowDomain: "allowDomain",
  environment: process.env.REACT_APP_ENVIRONMENT_CONFIG,
  AAA: "AAA",
  name: "Application",
  emailRegex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  loginMessage: "Please login to your account",
  invalidEmailMessage: "Please enter Valid Email!",
  invalidPasswordMessage: "Please enter password!",
  errorTittle: "ERROR",
  closeButton: "Close",
  submitButton: "Submit",
  resetButton: "Reset",
  getReport: "",
  userReport: "userReport",
  reportFormat : process.env.REACT_APP_USER_REPORT_FORMAT,
  actionType : process.env.REACT_APP_USER_ACTION_TYPE,
  internalYesNo: [{value: "yes"},{value: "no"}],
  userReport: "userReport",
  wrongEmailPassword: "Email id or password in incorrect. Please retry with Correct credentials",
  backendNotAvailable: "Error connecting to backend",
  reportingMessage: "Reporting is async process you will receive the report over mail",
  navBarColor: "#FAFAFA",
  logoutSession: "logoutSession",
  sessionTimeoutVal: 10000 * 60 * 3,
  constDelay: 1000 * 5,
  countdown: 60,
  countdownInterval: 1000,
  timeout:100 * 30 * 1,
  restricted: "restricted",
  responseStatus: [{value: "ok"},{value: "err"}],
  redirectUrlPattern: process.env.REACT_APP_REDIRECT_URL,
  bulkAssignRole: "bulkAssignRole",
  globalAdmin: "GLOBAL_ADMIN",
  appAdmin : "APP_ADMIN",
  bulkOption: process.env.REACT_APP_BULK_OPS,
  globalAdminMenuList: process.env.REACT_APP_GLOBAL_ADMIN_MENU_LIST,
  appAdminMenuList : process.env.REACT_APP_APP_ADMIN_MENU_LIST,
  userMenuList: process.env.REACT_APP_USER_MENU_LIST,
  };