import * as React from 'react';
import {useState,useEffect,useRef} from "react";
import "../assets/css/Content.css";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation } from "react-router-dom";
import {SubmitBtn} from "./FormComponent";
import IconButton from '@mui/material/IconButton';
import { configData } from "../config/config-dev";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import "../assets/css/Content.css";
import {Table,TableBody,TableCell,TableRow,FormControl,InputLabel,Select,MenuItem,Container, Stack } from '@mui/material';
import {DataGrid,GridToolbarQuickFilter,GridLogicOperator} from '@mui/x-data-grid';
import AppsIcon from '@mui/icons-material/Apps';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/joy/Button';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@mui/material/CircularProgress';


const applicationDetails = () => {

  const formTab={textTransform: 'none','font-weight': 'bold',fontSize: '15px','align-items': 'initial',minWidth: 90};
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const navigate=useNavigate();
  const sessionId = sessionStorage.getItem('SessionId');
  const viewAppUrl = configData.gateWayUrl + configData.application;
  const applicationsUrl = configData.gateWayUrl + configData.applicationForAdmin;
  const [applicationList,setApplicationList]=useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  var applicationNameData;


useEffect(()=>{
 	const getAppData=async ()=>{

 		await axios.post(applicationsUrl,{
 			  sessionId: sessionId
 		}).then(response=>{
 		if(response.data) {
 		    applicationNameData=response.data;
 		    setApplicationList(applicationNameData);
 		}
 		})
 		.catch(error=>{
 		console.error('Error while fetching application',error);
 	  });
 	  };
     getAppData();
    },[]);

  const viewApplication = (event, appId) => {

                                 axios.post(viewAppUrl, {
                                          sessionId: sessionId,
                                          applicationName: appId
                                          })
                                        .then(function (response) {
                                          if (response.data) {
                                           sessionStorage.setItem('ApplicationName',response.data.applicationName);
                                           navigate("/viewApplication", {state: response.data});
                                           } else {
                                           navigate("/");
                                          }
                                        })
                                        .catch(function (error) {
                                          navigate("/");
                                 });
    };

    return (
     <div>
                                         <Paper square>
                                               <Tabs
                                                 variant="plain"
                                                 color="neutral">
                                                 <Tab  style={formTab} label="Applications" />
                                               </Tabs>
                                             </Paper>
                                             <br/>
                  <Box id="form-box" className="application-form-box">
                  <Stack id="stack-row-first" spacing={4} direction="row" className="stack_app">
                  <Autocomplete required              onChange={viewApplication}
                                                      id="app_name"
                                                      options={applicationList.map((option) => option.applicationName)}
                                                      className="app-dropdown"
                                                      renderInput={(params) => <TextField {...params}
                                                              required
                                                              placeholder="Application"
                                                                  InputProps={{ ...params.InputProps,
                                                                  startAdornment: ( <InputAdornment position="start"> <SearchIcon/>
                                                                  </InputAdornment> ),
                                                                  disableUnderline: true
                                                                   }} />}

                                                   />
                {  /*   <Button id="app-search-btn" style={{background:'red', color: 'white', width: '10%',height: '44px',alignSelf: 'center'}}
                                          variant="contained"
                                          >
                                          See All
                    </Button>
 */ }
                    {loading && (
                                                                                            <CircularProgress
                                                                                              size={50}
                                                                                              sx={{
                                                                                                color: 'white',
                                                                                                position: 'absolute',
                                                                                                top: '34%',
                                                                                                left: '38%',
                                                                                              }}
                                                                                            />
                                                                                          )}

                 </Stack>
                  </Box>
     </div>
    );
};

export default applicationDetails;