import React, { useState } from "react";
import axios from "axios";
import { configData } from "../config/config-dev";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import validator from "validator";
import "../assets/css/Login.css";
import Header from "../components/Header";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import ChangePassword from "../components/ChangePassword";
import Button from "@mui/material/Button";
import Footer from "../components/Footer";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Table,TableCell,TableRow} from '@mui/material';
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import {
  Container,
  Content,
  Nav,
  Navbar,
  FlexboxGrid,
  Panel,
  Form,
  ButtonToolbar,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";

function Login() {


  const location = useLocation();
  const appData = location.state;
  const environment = configData.environment;
  const AAA = configData.AAA;
  const name = configData.name;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [schangepassword, setChangePassword] = React.useState(false);
  const [passwordToExpire, setPasswordToExpire] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const url = configData.gateWayUrl + configData.authenticateUrl;

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleReset = (e) => {
    e.preventDefault();
    setEmail("");
    setPassword("");
    setErrorMessage("");
  };
  const handleModalClose = () => {
    if (passwordToExpire) {
      setPasswordToExpire(false);
      setOpen(false);
      navigate("/dashboard");
    } else {
      setOpen(false);
    }
  };
  const handleAuthApi = () => {
    let emailRegex = configData.emailRegex;
    event.preventDefault();
    axios.defaults.withCredentials = true;
    setLoading(true);
    if (!emailRegex.test(email) || !validator.isEmail(email)) {
      setLoading(false);
      setErrorMessage(configData.invalidEmailMessage);
      setOpen(true);
    } else if (password == "") {
      setLoading(false);
      setErrorMessage(configData.invalidPasswordMessage);
      setOpen(true);
    } else {
      axios
        .post(url, {
          email: email,
          password: password,
        })
        .then(function (response) {
        setLoading(false);
          if (response.data.isSuccess) {
            sessionStorage.setItem("UserName", response.data.firstName + "  "+ response.data.lastName);
            sessionStorage.setItem("SessionId", response.data.sessionId);
            sessionStorage.setItem("isVodafoneUser",response.data.isVodafoneUser);
            sessionStorage.setItem("userRole", response.data.userRole);
            if (
              !response.data.isVodafoneUser &&
              response.data.passwordToExpire
            ) {
              setPasswordToExpire(true);
              setErrorMessage(response.data.errorMessage);
              setOpen(true);
            } else if (
              !response.data.isVodafoneUser &&
              response.data.passwordMustChange
            ) {
              setChangePassword(true);
            } else {
            if(appData!=null){
              	if((!appData || Object.keys(appData).length === 0) && appData.redirectUrl!=null || appData.redirectUrl!="" ) {
                    window.location.href = appData.redirectUrl;
                }else {
                     navigate("/dashboard");
                }
                }else{
                    navigate("/dashboard");
               }
            }
          } else if (
            response.data.errorMessage !== null &&
            response.data.errorMessage !== ""
          ) {
            setErrorMessage(response.data.errorMessage);
            setOpen(true);
          } else {
            setErrorMessage(
              configData.wrongEmailPassword );
            setOpen(true);
          }
        })
        .catch(function (error) {
          setErrorMessage(configData.backendNotAvailable);
          setOpen(true);
          setLoading(false);
        });
    }
  };
  const Divider = () => {
    return <hr style={{ borderTop: "1px solid grey" }}></hr>;
  };
  return (
    <div>
      <ChangePassword
        show={schangepassword}
        close={() => setChangePassword(false)}
      />
     <Header/>
      <Container>

        <div id="background_image" class="bg-img">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Panel
                shaded
                bordered
                style={{ marginTop: "3%", backgroundColor: "white" , minWidth: 300}}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="logo_image"
                    alt="Alt content"
                    src={require("./../assets/images/vodafone.png")}
                  />
                </div>
                <div id="label-title" class="label-title">
                  <span>{configData.loginMessage}</span>
                </div>
                <div class="info-panel"></div>
                <div class="info-panel"></div>
                <div class="info-panel"></div>
                <Form id="loginForm" onSubmit={() => handleAuthApi()}>
                  <table id="login" border="0">
                    <tbody>
                      <tr>
                        <td>
                          <div class="input-icons">
                            <i class="fa fa-fw fa-user icon"></i>
                            <input
                              class="input-field"
                              value={email}
                              onChange={handleEmail}
                              id="inputEmail"
                              placeholder="Email/UserId"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="info-panel"></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="input-icons">
                            <i class="fa fa-fw fa-lock icon" aria-hidden="true"></i>
                            <input
                              class="input-field"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={handlePassword}
                              id="inputPassword"
                              placeholder="Password"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Form.Group>
                    <FlexboxGrid justify="center" style={{ margin: 20 }}>
                      <ButtonToolbar>
                        <Button
                          variant="contained"
                          color="error"
                          id="loginSubmit"
                          type="submit"
                        >
                          {configData.submitButton}
                        </Button>
                         <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}
                              >
                                <CircularProgress color="inherit" />
                              </Backdrop>
                        <Button
                          variant="contained"
                          color="error"
                          id="loginReset"
                          onClick={handleReset}
                          style={{background:'grey'}}
                        >
                          {configData.resetButton}
                        </Button>
                      </ButtonToolbar>
                    </FlexboxGrid>
                  </Form.Group>
                </Form>
              </Panel>
            </div>
          </div>
          <Footer />
          <Modal
            open={open}
            onClose={handleModalClose}
            id="modal-head"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 1,
            }}
          >
            <Sheet
              variant="outlined"
              id="modal-sheet-head"
              sx={{
                maxWidth: 300,
                minWidth: 200,
                borderRadius: "lg",
                p: 3,
                boxShadow: "lg",
                border: 1.5,
              }}
            >
              <ModalClose
                variant="solid"
                id="modal-close"
                sx={{
                  m: 1,
                  border: 1,
                  size: "lg",
                  bgcolor: "red",
                  justifyContent: "center",
                  margin: -.5,
                }}
              />
              <Panel
                id="modal-Panel"
                shaded
                bordered
                style={{
                  marginTop: "12%",
                  backgroundColor: "white",
                }}
              >
                <div id="modal-title" class="modal-title">
                  <span>{configData.errorTittle}</span>
                </div>
                <Divider />
                <div id="modal-desc" class="modal-desc">
                  <span>{errorMessage}</span>
                </div>
              </Panel>
              <FlexboxGrid id="modal-flexboxgrid"justify="center" style={{ margin: 15 }}>
                <Button
                  variant="contained"
                  color="error"
                  id="closeModal"
                  onClick={handleModalClose}
                  fullWidth="true"
                >
                  {configData.closeButton}
                </Button>
              </FlexboxGrid>
            </Sheet>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default Login;
