import HomeIcon from "@mui/icons-material/HomeOutlined";
import ApplicationIcon from '@mui/icons-material/Apps';
import UsersIcon from "@mui/icons-material/PeopleOutlined";
import SessionsIcon from '@mui/icons-material/GridView';
import ReportingIcon from "@mui/icons-material/ReceiptOutlined";
import BulkOperationIcon from '@mui/icons-material/UploadFile';

export const config =
{
  globalAdminMenuList: '[{ "name": "Home", "path": "/dashboard", "icon": "HomeIcon" },{ "name": "Application", "path": "/applications", "icon": "ApplicationIcon" },{ "name": "Reporting", "path": "/reporting", "icon": "ReportingIcon" },{ "name": "Bulk Operation", "path": "/upload", "icon": "BulkOperationIcon" }, { "name": "Users", "path": "/userdetails", "icon": "UsersIcon" },{ "name": "Sessions", "path": "/sessions", "icon": "SessionsIcon" }]',
  appAdminMenuList : '[{ "name": "Home", "path": "/dashboard", "icon": "HomeIcon" },{ "name": "Reporting", "path": "/reporting", "icon": "ReportingIcon" },{ "name": "Bulk Operation", "path": "/upload", "icon": "BulkOperationIcon" }]',
  userMenuList: '[{ "name": "Home", "path": "/dashboard", "icon": "HomeIcon" }]'
};

