// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div_footer {
background: #d3d3d30a;
margin-top: 5%;
font-size: 18px;
overflow: hidden;
color: black;
}
.paragraph-title{
 text-align: center;
 color: #e81414fa;
}
.paragraph-title:hover {
  background-color: #ffffff;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Footer.css"],"names":[],"mappings":"AAAA;AACA,qBAAqB;AACrB,cAAc;AACd,eAAe;AACf,gBAAgB;AAChB,YAAY;AACZ;AACA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".div_footer {\nbackground: #d3d3d30a;\nmargin-top: 5%;\nfont-size: 18px;\noverflow: hidden;\ncolor: black;\n}\n.paragraph-title{\n text-align: center;\n color: #e81414fa;\n}\n.paragraph-title:hover {\n  background-color: #ffffff;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
